import React from "react";
import styled, { useTheme } from "styled-components";
import { func, string } from "prop-types";
import { useTranslation } from "@lib/i18n";
import { withTabNavigation } from "@hoc/withTabNavigation";

import ArrowLeftIcon from "../../shared/Icons/ArrowLeft";
import { color, font, fontWeight } from "../../shared/utils";
import Typography from "../../shared/Typography";

const Container = styled.div`
  align-items: flex-start;
  background: ${({ backgroundUrl }) => backgroundUrl} no-repeat center center;
  background-size: cover;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 156px;
  justify-content: space-between;
  padding: 0.75rem;
  text-transform: uppercase;
  width: 100%;
`;

const CloseButton = withTabNavigation(
  styled.div`
    display: flex;
    align-items: center;
    color: ${color("white")};
    transition: 0.1s linear color;

    &:hover {
      color: ${color("grey")};
    }
  `,
  "button",
  true
);

const StyledArrowLeftIcon = styled(ArrowLeftIcon).attrs({
  width: "16px",
  height: "24px",
})`
  margin-right: 0.5rem;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 0 0 1px);
`;

const StyledCitroenTypography = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
  text-shadow: #000 0 0 1px;
`;

const ShownPackage = ({ onClick, title, backgroundUrl }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container backgroundUrl={backgroundUrl}>
      <CloseButton
        data-id={`customize-package-${title}-close-button`}
        onClick={onClick}
      >
        <StyledArrowLeftIcon height="16px" width="16px" />
        <StyledCitroenTypography size="sm" customColor="currentColor">
          {t("customize.close")}
        </StyledCitroenTypography>
      </CloseButton>
      <StyledCitroenTypography
        customColor={theme.colors.white}
        data-id={`customize-package-title-${title}`}
      >
        {title}
      </StyledCitroenTypography>
    </Container>
  );
};

ShownPackage.propTypes = {
  onClick: func,
  title: string.isRequired,
  backgroundUrl: string.isRequired,
};

export default ShownPackage;
