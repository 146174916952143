import React from "react";
import { bool, func } from "prop-types";
import { useTranslation } from "@lib/i18n";
import styled from "styled-components";

import PackageDetailDescription from "./PackageDetailDescription";
import Typography from "@components/shared/Typography";
import RequestButton from "@components/shared/RequestButton";
import FinancePrice from "@components/shared/FinancePrice";
import ScrollableContainer from "@components/shared/ScrollableContainer";

import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { getIsCountry, COUNTRIES } from "@lib/getIsCountry";
import { personalizeOptionType } from "@types";
import { useIsCashJourneySelected } from "@hooks/useIsCashJourneySelected";
import { useIsLOAJourneySelected } from "@hooks/useIsLOAJourneySelected";
import { useIsVACJourneySelected } from "hooks/useIsVACJourneySelected";
import { usePrimaryColor } from "@hooks/usePrimaryColor";

import { useAccessoryIncrease } from "@hooks/finance-data/useAccessoryIncrease";
import { useGetFormattedPrice } from "@hooks/useGetFormattedPrice";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { useGetJourneySpecificConfig } from "@hooks/useGetJourneySpecificConfig";
import { priceAlgorithm } from "@lib/priceAlgorithm";
import { useVehicleQuantitySelector } from "@redux/reducers/deal";

import {
  color,
  font,
  fontSize,
  fontWeight,
  hyphenate,
} from "@components/shared/utils";

const Container = styled.div`
  width: 100%;
  padding-bottom: 1.5rem;
`;

const Action = styled.div`
  padding: 2rem 0;
  position: relative;
  display: flex;
  align-items: center;
  color: ${color("black")};
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  max-width: 6.75rem;
`;

const StyledImage = styled.img`
  border-radius: 5px;
  height: 6.75rem;
  object-fit: cover;
  width: 6.75rem;
`;

const StyledSuperscript = styled.sup`
  font-size: ${fontSize("sm")};
  padding-left: 0.3rem;
`;

const StyledFigCaption = styled.figcaption`
  color: ${color("black")};
  font-size: ${fontSize("sm")};
  margin-top: ${fontSize("sm")};
  white-space: initial;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const CustomizeIncludeTitle = styled(Typography)`
  text-transform: uppercase;
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
`;

const StyledRequestButton = styled(RequestButton)`
  font-weight: ${fontWeight("bold")};
  min-width: 6rem;
  padding: 1rem 2rem;
`;

const Price = styled(Typography)`
  font-family: ${font("citroen")};
  font-size: ${fontSize("xxl")};
  font-weight: ${fontWeight("bold")};
  text-transform: uppercase;
`;

const StyledMathCharacter = styled.span`
  padding: 0 0.2rem;
`;

const PackageDetail = ({
  changeConfiguration,
  isSelected,
  option,
  withGallery,
  readOnly,
}) => {
  const { personalizationOptions, netPrice, label, description, id } = option;
  const financeAccessoryIncrease = useAccessoryIncrease(id);
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();
  const isCashJourneySelected = useIsCashJourneySelected();
  const isLOAJourneySelected = useIsLOAJourneySelected();
  const isVACJourneySelected = useIsVACJourneySelected();
  const isB2BSelected = useIsB2BSelected();
  const { multipleVehicles } = useGetJourneySpecificConfig();
  const getFormattedPrice = useGetFormattedPrice();
  const quantity = useVehicleQuantitySelector();
  const isMultipleVehiclesAllowed = multipleVehicles?.enabled;
  const isSinglePaymentPriceFormat =
    isCashJourneySelected ||
    isLOAJourneySelected ||
    (getIsCountry(COUNTRIES.BE) && isVACJourneySelected);

  const price = priceAlgorithm.times(netPrice, quantity);

  return (
    <Container>
      {!readOnly && (
        <Action primaryColor={primaryColor}>
          <StyledRequestButton
            withBorder
            data-id={`customize-${isSelected ? "remove" : "add"}-package`}
            triggerRequestCallback={() => changeConfiguration(option)}
          >
            {isSelected ? t("customize.remove") : t("customize.add")}
          </StyledRequestButton>
          <Price data-id={`price-package-detail-${label}`}>
            {isSinglePaymentPriceFormat ? (
              <>
                {price >= 0 && (
                  <StyledMathCharacter>
                    {`${t("mathSymbols.plus")}`}
                  </StyledMathCharacter>
                )}
                {`${getFormattedPrice(price)}`}
              </>
            ) : (
              <>
                {financeAccessoryIncrease && ""}
                {isMultipleVehiclesAllowed && (
                  <>{t("mathSymbols.times", { quantity })} </>
                )}
                {financeAccessoryIncrease && financeAccessoryIncrease >= 0 && (
                  <StyledMathCharacter>
                    {t("mathSymbols.plus")}
                  </StyledMathCharacter>
                )}
                <FinancePrice amount={financeAccessoryIncrease} />
                {financeAccessoryIncrease && (
                  <StyledSuperscript>
                    {isB2BSelected
                      ? t("price.b2b.month")
                      : t("price.b2c.month")}
                  </StyledSuperscript>
                )}
              </>
            )}
          </Price>
        </Action>
      )}
      <div data-id="customize-include">
        <TextContainer>
          <CustomizeIncludeTitle
            data-id={`customize-include-item-name-${hyphenate(label)}`}
            margin="0.5rem 0"
          >
            {label}
          </CustomizeIncludeTitle>
          <PackageDetailDescription description={description} />
        </TextContainer>
        {withGallery && (
          <ScrollableContainer>
            {personalizationOptions.map(({ id, label }) => (
              <ImageContainer data-id={`customize-image-${label}`} key={id}>
                <StyledImage
                  src={getStaticAssetURL(
                    `/images/gallery/personalization/${id}.jpg`
                  )}
                  alt={label}
                  title={label}
                />
                <StyledFigCaption>{label}</StyledFigCaption>
              </ImageContainer>
            ))}
          </ScrollableContainer>
        )}
      </div>
    </Container>
  );
};

PackageDetail.propTypes = {
  changeConfiguration: func.isRequired,
  isSelected: bool.isRequired,
  option: personalizeOptionType.isRequired,
  withGallery: bool,
  readOnly: bool,
};

export default PackageDetail;
