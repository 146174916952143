import React from "react";
import styled from "styled-components";
import { string, node } from "prop-types";

import { desktop, prop } from "@components/shared/utils";

const Container = styled.div`
  display: ${prop("display")};
  width: 100%;

  ${desktop`
    display: none;
  `}
`;

const MobileContainer = ({ display = "flex", children, ...props }) => {
  return (
    <Container display={display} {...props}>
      {children}
    </Container>
  );
};

MobileContainer.propTypes = {
  children: node.isRequired,
  display: string,
};

export default MobileContainer;
