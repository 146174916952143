import React, { useState, useEffect } from "react";
import { array, bool, number, string } from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "@lib/i18n";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Typography from "../shared/Typography";
import ClosedPackage from "./shared/ClosedPackage";
import PackageDetail from "./shared/PackageDetail";
import PackageDetailDescription from "./shared/PackageDetailDescription";
import ServicesDetail from "./shared/ServicesDetail";
import ShownPackage from "./shared/ShownPackage";
import ScrollableContainer from "../shared/ScrollableContainer";

import CollapsableContainer, {
  CollapsableContainerHeader,
  CollapsableContainerTitleContainer,
} from "@components/shared/CollapsableContainer";

import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { CUSTOMIZE_TYPES } from "@shared/constants";
import { font, fontSize, fontWeight, tablet } from "../shared/utils";
import { updateCarConfigurationAction } from "@redux/actions/dealActions";
import { setLastChangedAtAction } from "@redux/actions/deprecatedActions";
import { usePrimaryColor } from "@hooks/usePrimaryColor";
import gtm from "@gtm/core";

import {
  useCarConfigurationSelector,
  useActiveCarPreconfigurationSelector,
} from "@redux/reducers/deal";

import {
  openCustomizeSectionEvent,
  openCustomizeDetailEvent,
  changeSelectionEvent,
} from "@gtm/events/homepage";

const PackageContainer = styled(ScrollableContainer)`
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1.5rem;
  overflow-x: auto;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 1.5rem;
`;

const TotalItemsCount = styled.sup`
  font-size: 0.7rem;
  padding-right: 2rem;

  ${tablet`
    padding-right: 1rem;
  `}
`;

const TitleCountContainer = styled.div`
  color: inherit;
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("md")};
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: ${fontSize("md")};
`;

const HeaderTypography = styled(Typography)`
  font-weight: ${fontWeight("bold")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("md")};
  text-transform: lowercase;
  user-select: none;
`;

const StyledCollapsableContainer = styled(CollapsableContainer)`
  padding: 0 2rem !important;

  & ${CollapsableContainerHeader} {
    color: ${({ isOpen, primaryColor, theme }) =>
      isOpen ? primaryColor : theme.colors.white};
    &:hover {
      color: ${({ isOpen, isDisabled, theme }) =>
        !isOpen && !isDisabled && theme.colors.black};
    }
    padding: 1.5rem 0;
  }

  & ${CollapsableContainerTitleContainer} {
    margin: 0;
  }
`;

const toggleElementInArray = (currentItemsArray, toggledItem) => {
  if (currentItemsArray.some(({ id }) => id === toggledItem.id)) {
    return currentItemsArray.filter(({ id }) => id !== toggledItem.id);
  }

  return [...currentItemsArray, toggledItem];
};

const CustomizeItem = ({
  defaultOpen = false,
  disabled = false,
  packages,
  readOnly,
  title,
  type,
}) => {
  const [isOpen, setOpenCustomize] = useState(defaultOpen);
  const [openPackageId, setOpenPackageId] = useState();
  const openPackage = packages.find(pack => pack.id === openPackageId);
  const primaryColor = usePrimaryColor();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const configuration = useCarConfigurationSelector();
  const activePreconfiguration = useActiveCarPreconfigurationSelector();
  const selectedItems = configuration[type] ?? [];

  const isTypeService = type === CUSTOMIZE_TYPES.SERVICE;

  const onPackageClick = id =>
    setOpenPackageId(currentValue => (currentValue === id ? null : id));

  const updateCarConfiguration = async option => {
    if (type === CUSTOMIZE_TYPES.SERVICE) {
      const isRemove = selectedItems.some(({ id }) => id === option.id);

      await dispatch(
        updateCarConfigurationAction({ [type]: isRemove ? [] : [option] })
      );

      dispatch(setLastChangedAtAction(Date.now()));

      return;
    }

    await dispatch(
      updateCarConfigurationAction({
        [type]: toggleElementInArray(selectedItems, option),
      })
    );

    dispatch(setLastChangedAtAction(Date.now()));
  };

  const changeConfiguration = async option => {
    await updateCarConfiguration(option);

    setOpenPackageId(null);

    setOpenCustomize(defaultOpen);

    const isSelected = !selectedItems.some(pack => pack.id === openPackageId);

    gtm.fire(
      changeSelectionEvent({
        action: isSelected ? "Add" : "Remove",
        label: option.label,
      })
    );
  };

  useEffect(() => {
    setOpenPackageId(null);
    setOpenCustomize(defaultOpen);
  }, [activePreconfiguration]);

  const singlePackage = packages.length === 1;

  const onCollapsableContainerChange = isContainerOpen => {
    setOpenCustomize(!isContainerOpen);

    if (!isContainerOpen) {
      gtm.fire(openCustomizeSectionEvent(t(`customize.${title ?? type}`)));
    }
  };

  return (
    <StyledCollapsableContainer
      primaryColor={primaryColor}
      data-id={`customize-item-container-${type}`}
      isOpen={isOpen}
      isDisabled={disabled}
      defaultOpen={defaultOpen}
      onChange={onCollapsableContainerChange}
      title={
        <TitleContainer
          data-id={`${type}-selected-count`}
          isOpen={isOpen}
          isDisabled={disabled}
        >
          <HeaderTypography customColor="currentColor" forwardedAs={"h2"}>
            {t(`customize.${title ?? type}`)}
          </HeaderTypography>
          {!readOnly && (
            <TitleCountContainer>
              {selectedItems.length}
              <TotalItemsCount>/{packages.length}</TotalItemsCount>
            </TitleCountContainer>
          )}
        </TitleContainer>
      }
    >
      {type === CUSTOMIZE_TYPES.PACK && activePreconfiguration.description && (
        <StyledTypography>
          <PackageDetailDescription description={activePreconfiguration.description} />
        </StyledTypography>
      )}
      <PackageContainer isVisible={isOpen}>
        {packages
          .sort((a, b) => {
            // Services should not be sorted by selected ones
            // as only one service can be selected at the time
            if (isTypeService) {
              return 0;
            }

            return (
              selectedItems.some(pack => pack.id === b.id) -
              selectedItems.some(pack => pack.id === a.id)
            );
          })
          .map(({ id, label, type: packType }, index) =>
            openPackage?.id === id ? (
              <ShownPackage
                onClick={() => onPackageClick(openPackage.id)}
                key={index}
                title={openPackage.label}
                backgroundUrl={`url("${getStaticAssetURL(
                  `/images/gallery/${packType}/${
                    isTypeService ? index + 1 : id
                  }_big.jpg`
                )}")`}
              />
            ) : (
              openPackage?.id === undefined && (
                <ClosedPackage
                  onClick={() => {
                    onPackageClick(id);
                    gtm.fire(
                      openCustomizeDetailEvent({
                        action: t(`customize.${title ?? packType}`),
                        label,
                      })
                    );
                  }}
                  key={index}
                  title={label}
                  backgroundUrl={`url("${getStaticAssetURL(
                    `/images/gallery/${packType}/${
                      isTypeService ? index + 1 : id
                    }${singlePackage ? "_big" : ""}.jpg`
                  )}")`}
                  isSelected={
                    packType === CUSTOMIZE_TYPES.SERVICE
                      ? selectedItems.some(pack => pack.groupLabel === label)
                      : selectedItems.some(pack => pack.id === id)
                  }
                  singleItem={packages.length === 1}
                />
              )
            )
          )}
      </PackageContainer>
      <AnimatePresence initial={false}>
        {openPackage?.id && (
          <motion.div
            variants={{
              open: {
                opacity: 1,
                height: "auto",
                display: "block",
              },
              collapsed: {
                opacity: 0,
                height: 0,
                transitionEnd: { display: "none" },
                transition: { duration: 0.3, type: "tween" },
              },
            }}
            animate="open"
            initial="collapsed"
            exit="collapsed"
          >
            {!isTypeService ? (
              <PackageDetail
                changeConfiguration={changeConfiguration}
                isSelected={
                  !!selectedItems.find(pack => pack.id === openPackage.id)
                }
                option={openPackage}
                readOnly={readOnly}
                withGallery={
                  type === CUSTOMIZE_TYPES.PACK ||
                  (type === CUSTOMIZE_TYPES.ACCESSORY &&
                    !!openPackage.personalizationOptions.length)
                }
              />
            ) : (
              <ServicesDetail
                onServiceButtonClick={changeConfiguration}
                selectedServices={selectedItems}
                availableServices={openPackage.services}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </StyledCollapsableContainer>
  );
};

CustomizeItem.propTypes = {
  defaultOpen: bool,
  disabled: bool,
  type: string.isRequired,
  packages: array.isRequired,
  index: number,
  readOnly: bool,
  singleItem: bool,
  title: string,
};

export default CustomizeItem;
