import React from "react";
import styled from "styled-components";
import { elementType } from "prop-types";

import Typography from "../shared/Typography";

import { color } from "../shared/utils";

const StyledTypography = styled(Typography)`
  color: ${color("errorRed")};
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: center;
  padding-bottom: 1rem;
`;

const ErrorMessage = ({ children }) => (
  <StyledTypography>{children}</StyledTypography>
);

ErrorMessage.propTypes = {
  children: elementType,
};

export default ErrorMessage;
