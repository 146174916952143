import React from "react";

import BaseIcon from "../BaseIcon";

const PlusCircleIcon = props => (
  <BaseIcon viewBox="0 0 20 20" {...props}>
    <line x1="10" y1="0" x2="10" y2="20" strokeWidth="3"></line>
    <line x1="0" y1="10" x2="20" y2="10" strokeWidth="3"></line>
  </BaseIcon>
);

export default PlusCircleIcon;
