import React, { useRef } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { useTranslation } from "@lib/i18n";

import ContentWrapper from "@components/shared/ContentWrapper";
import CircularLoader from "@components/shared/Icons/CircularLoader";
import Typography from "@components/shared/Typography";
import Customize from "../Customize/Customize";
import CarGallery from "../CarGallery/CarGallery";
import DiscountStripe from "@components/DiscountStripe/DiscountStripe";
import PreconfigurationList from "../PreconfigurationPicker/PreconfigurationList";
import CarouselAndFinanceBar from "../CarouselAndFinanceBar/CarouselAndFinanceBar";
import CompareButtonContainer from "@components/CompareButtonContainer/CompareButtonContainer";
import MobileContainer from "@components/shared/MobileContainer";
import CollapsableContainerPreconfigurations from "../CarouselAndFinanceBar/CollapsableContainerPreconfigurations";

import {
  useDiscountStripeSelector,
  useEcoBonusSelector,
  useMovesProgramSelector,
} from "@redux/reducers/appConfig";
import { useJourneySpecificScrappage } from "@hooks/useJourneySpecificScrappage";
import { desktop, tabletLarge } from "@components/shared/utils";
import { usePrimaryColor } from "@hooks/usePrimaryColor";

import { useIsScrappageSelectedSelector } from "@redux/reducers/deal";
import { useIsFixed } from "@hooks/useIsFixed";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10%;
  flex-direction: column;

  ${desktop`
  flex-direction: row;
    margin-top: 1rem;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${desktop`
    display: block;
    width: 50%;
    min-width: 50%;
  `}
`;

const StyledHelper = styled.div`
  margin-bottom: 1rem;
  position: ${({ isFixed }) => (isFixed ? "fixed" : "relative")};
  top: ${({ isFixed }) => isFixed && "0"};
  width: 100%;
  z-index: 999;

  ${desktop`
    order: 1;
    min-width: 425px;
  `}
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledMobileContainer = styled(MobileContainer)`
  flex-direction: column;
`;

const StyledDiscountStripe = styled(DiscountStripe)`
  ${desktop`
    position: fixed;
    top: 14.5rem;
    width: calc((100% - 14rem) / 2);
    right: 4rem;
    max-width: 32.5rem;
  `}

  @media (min-width: ${({ theme }) => theme.contentWidth}) {
    right: ${({ theme }) => `calc((100% + 4rem - ${theme.contentWidth}) / 2)`};
  }
`;

/* When the window width is less than 768px, the price detail bar and discount stripe
   must become fixed, previously this was done with fixed magic numbers in those two
   elements, it's moved here to avoid incorrect positioning of the discount stripe.
   The when-to-fix calculation should use parent's y position but since the parent 
   here includes the image carousel that's unreliable, so this dummy element is used */
const FixedPositioner = styled("div")`
  ${tabletLarge`
    display: none;
  `}
`;

const CarConfigurator = ({
  is360Disabled,
  isFinancingOpen,
  onIsFinancingOpenChange,
  isPromoCodeApplicable,
}) => {
  const primaryColor = usePrimaryColor();
  const isScrappageSelected = useIsScrappageSelectedSelector();

  const { t } = useTranslation();

  const discountStripe = useDiscountStripeSelector();
  const ecoBonus = useEcoBonusSelector();

  const scrappage = useJourneySpecificScrappage();

  const isDiscountStripeEnabled = discountStripe?.enabled;

  const movesProgram = useMovesProgramSelector();
  const isMovesProgramEnabled = movesProgram?.enabled;

  const ref = useRef(null);
  const styledHelperRef = useRef(null);

  let styledHelperHeight = 0;
  if (styledHelperRef.current) {
    const { height } = styledHelperRef.current.getBoundingClientRect();
    styledHelperHeight = height;
  }

  const isFixed = useIsFixed(() => {
    if (!ref.current) {
      return false;
    }

    const { y } = ref.current.getBoundingClientRect();

    return y < 0;
  });

  return (
    <ContentWrapper
      isWholeWidth
      color={primaryColor}
      dataId="car-configurator-container"
    >
      <Container>
        <Wrapper data-id="car-selection-wrapper">
          <StyledMobileContainer>
            <CollapsableContainerPreconfigurations>
              {({ isOpen }) => (
                <>
                  <PreconfigurationList isOpen={isOpen} />
                  <CompareButtonContainer />
                </>
              )}
            </CollapsableContainerPreconfigurations>
          </StyledMobileContainer>
          {is360Disabled ? (
            <LoaderContainer>
              <CircularLoader width="190" height="190" />
              <Typography customColor="currentColor">{t("loading")}</Typography>
            </LoaderContainer>
          ) : (
            <CarGallery />
          )}

          <FixedPositioner ref={ref} />

          <StyledHelper ref={styledHelperRef} id="helper" isFixed={isFixed}>
            <StyledMobileContainer>
              <CarouselAndFinanceBar
                isPromoCodeApplicable={isPromoCodeApplicable}
                onIsFinancingOpenChange={onIsFinancingOpenChange}
              />
            </StyledMobileContainer>
            {(isDiscountStripeEnabled || isMovesProgramEnabled) && (
              <StyledDiscountStripe
                isScrappageSelected={isScrappageSelected}
                isScrappageEnabled={scrappage.enabled}
                governmentDiscountPercentage={
                  ecoBonus.governmentDiscountPercentage
                }
                scrappageDiscountPercentage={scrappage.discountPercentage}
                withEcoBonus={ecoBonus.enabled}
                isMovesProgramEnabled={isMovesProgramEnabled}
              />
            )}
          </StyledHelper>
        </Wrapper>
        <Customize
          isFinancingOpen={isFinancingOpen}
          isPromoCodeApplicable={isPromoCodeApplicable}
          onIsFinancingOpenChange={onIsFinancingOpenChange}
          fixedHeaderHeight={styledHelperHeight}
        />
      </Container>
    </ContentWrapper>
  );
};

CarConfigurator.propTypes = {
  isFinancingOpen: bool.isRequired,
  onIsFinancingOpenChange: func.isRequired,
  is360Disabled: bool,
  isPromoCodeApplicable: bool,
};

export default React.memo(CarConfigurator);
