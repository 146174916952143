import React from "react";
import styled from "styled-components";
import { string, node } from "prop-types";

import { desktop, prop } from "@components/shared/utils";

const Container = styled.div`
  display: none;

  ${desktop`
    display: ${prop("display")};
    width: 100%;
  `}
`;

const DesktopContainer = ({ display = "flex", children, ...props }) => {
  return (
    <Container display={display} {...props}>
      {children}
    </Container>
  );
};

DesktopContainer.propTypes = {
  children: node.isRequired,
  display: string,
};

export default DesktopContainer;
