import React from "react";
import styled from "styled-components";
import { useTranslation } from "@lib/i18n";

import Typography from "@components/shared/Typography";

import { openDocument } from "@lib/openDocument";
import { color, desktop, fontSize, fontWeight } from "../shared/utils";
import gtm from "@gtm/core";
import { compareEquipmentsClickEvent } from "@gtm/events/homepage";
import { useGetAssetPath } from "hooks/useGetAssetPath";
import { withTabNavigation } from "@hoc/withTabNavigation";
import { PDF_DOCUMENT_TYPES } from "@shared/constants";

const Space = styled.div`
  height: 1rem;
`;

const Container = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${desktop`
    text-align: left;
    min-width: 8rem;
    margin-left: 0.5rem;
    margin-bottom: auto;
    margin-top: auto;
  `}
`;

const StyledTypography = styled(Typography)`
  color: ${color("white")};
  cursor: pointer;
  display: flex;
  font-size: ${fontSize("sm")};
  font-weight: ${fontWeight("regular")};
  justify-content: center;
  position: relative;
  text-transform: uppercase;

  ${desktop`
    font-size: ${fontSize("xs")};
  `}

  &:hover {
    font-weight: ${fontWeight("bolder")};
  }
`;

const StyledTypographyWithTabNavigation = withTabNavigation(StyledTypography);

const StyledSpan = styled.span`
  display: inline;
  border-bottom: 1px solid ${color("white")};
  width: fit-content;
  margin: 1rem 0;
`;

const CompareButtonContainer = () => {
  const { t, i18n } = useTranslation();
  const getAssetPath = useGetAssetPath(i18n.language);

  const containsTranslation =
    !!t("compareButtonContainer.compare") &&
    !!t("compareButtonContainer.preconfigurations");

  if (!containsTranslation) return <Space />;

  const clickOpenDocument = () => {
    gtm.fire(
      compareEquipmentsClickEvent({
        label: `${t("compareButtonContainer.compare")} ${t(
          "compareButtonContainer.preconfigurations"
        )}`,
      })
    );

    openDocument(
      getAssetPath(PDF_DOCUMENT_TYPES.COMPARISON_OF_PRECONFIGURATIONS)
    );
  };

  return (
    <Container>
      <StyledTypographyWithTabNavigation onClick={clickOpenDocument}>
        <StyledSpan>
          {t("compareButtonContainer.compare") +
            t("compareButtonContainer.preconfigurations")}
        </StyledSpan>
      </StyledTypographyWithTabNavigation>
    </Container>
  );
};

export default CompareButtonContainer;
