import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import { bool, func } from "prop-types";
import { motion } from "framer-motion";
import { useTranslation } from "@lib/i18n";

import ArrowDownIcon from "@components/shared/Icons/ArrowDown";
import PlusIcon from "@components/shared/Icons/PlusIcon";
import TitlePreconfiguration from "./TitlePreconfiguration";
import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";
import { useDetectDevice } from "@hooks/useDetectDevice";
import { usePreconfigurationsSelector } from "@redux/reducers/appConfig";
import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { withTabNavigation } from "@hoc/withTabNavigation";

import {
  fontWeight,
  font,
  fontSize,
  color,
  desktop,
  tablet,
} from "@components/shared/utils";

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  position: ${({ isOpen }) => (isOpen ? "fixed" : "relative")};
  width: ${({ isOpen }) => isOpen && "100%"};
  top: ${({ isOpen }) => (isOpen ? 0 : "unset")};
  left: ${({ isOpen }) => (isOpen ? 0 : "unset")};
  height: ${({ isOpen }) => (isOpen ? "100vh" : "unset")};
  z-index: 100;

  ${desktop`
    height: unset;
    position: relative;
    width: unset;
    top: unset;
    left: unset;
    background-color: ${color("black1")};
    justify-content: flex-start;
  `}
`;

const CollapsableContainerHeader = styled.div`
  height: 7rem;
  align-items: center;
  display: flex;
  font-size: ${fontSize("lg")};
  justify-content: space-between;
  transition: color 0.2s linear;
  padding: 0 0.5rem;
  background-color: ${color("black1")};

  ${desktop`
    background-color: unset;
    padding: 0 0;
  `}
`;

const CollapsableContainerTitleContainer = styled.div`
  color: inherit;
  font-family: ${font("citroen")};
  font-size: inherit;
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.5px;
  margin: 0.5rem 0;
  text-transform: uppercase;
  user-select: none;
`;

const RightItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OtherPreconfigurationsBanner = styled.div`
  color: ${color("lightGrey")};
  font-size: ${fontSize("sm")};
  font-weight: ${fontWeight("light")};
  padding-right: 0.3rem;
  line-height: 0.9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    display: block;
  }

  ${tablet`
    padding-right: 1rem;
  `}

  ${desktop`
    padding-left: 1rem;
  `}
`;

const ArrowBorder = styled.span`
  padding: 0.4rem 0.8rem;
  border: 2px solid ${color("white")};
  border-radius: 5px;
  cursor: pointer;

  ${tablet`
    padding: 0.6rem 1rem;
  `}
`;

const TabNavigationWrapper = withTabNavigation(styled.div`
  width: 100%;
  height: 100%;
`);

const StyledArrowDownIcon = styled(ArrowDownIcon)`
  display: ${({ isOpen }) => (isOpen ? "none" : "inline")};
  transform: ${({ isOpen }) => isOpen && "rotate(180deg)"};
  color: ${color("white")};

  ${desktop`
    display: inline;
  `}
`;

const StyledPlusIcon = styled(PlusIcon)`
  display: ${({ isOpen }) => (isOpen ? "inline" : "none")};

  transform: rotate(45deg);
  color: ${color("white")};
  stroke: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.white : "currentColor"};

  ${desktop`
    display: none;
  `}
`;

const MotionContainerWrap = styled.div`
  height: ${({ isOpen, isDesktop }) => (isOpen && !isDesktop ? "100%" : 0)};
  width: 100%;
`;

const MotionContainer = styled(motion.div)`
  overflow-y: ${({ isOpen }) => !isOpen && "hidden"};
  background: ${color("black1")};
  position: relative;
  z-index: 100;
  width: 100%;

  border: ${({ isOpen, primaryColor }) =>
    isOpen && `solid 2px ${primaryColor}`};

  ${desktop`
    border: none;
    border-top: ${({ isOpen, primaryColor }) =>
      isOpen && `solid 2px ${primaryColor}`}; 
  `}
`;

const CollapsableContainerWrap = styled.div`
  width: 100%;

  ${desktop`
    width: unset;
  `}
`;

const OtherPreconfigurationsLabel = ({ isOpen }) => {
  const { t } = useTranslation();
  const preconfigurations = usePreconfigurationsSelector();

  return (
    <>
      {isOpen ? null : (
        <OtherPreconfigurationsBanner>
          <span>
            {t("preconfiguration.numberOthers", {
              count: preconfigurations.length - 1,
            })}
          </span>
          <span>{t("preconfiguration.models")}</span>
        </OtherPreconfigurationsBanner>
      )}
    </>
  );
};

OtherPreconfigurationsLabel.propTypes = {
  isOpen: bool,
};

const CollapsableContainerPreconfigurations = ({ children }) => {
  const { isDesktop } = useDetectDevice();
  const { t } = useTranslation();
  const preconfigurations = usePreconfigurationsSelector();

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);
  const ctaRef = useRef(null);

  const activePreconfiguration = useActiveCarPreconfigurationSelector();
  const primaryColor = usePrimaryColor();

  useEffect(() => {
    setIsOpen(false);
  }, [activePreconfiguration.id]);

  useEffect(() => {
    const onClickAway = event => {
      if (
        dropdownRef.current?.contains(event.target) ||
        event.target === dropdownRef.current ||
        ctaRef.current?.contains(event.target) ||
        event.target === ctaRef.current
      ) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener("click", onClickAway, true);

    return () => {
      document.removeEventListener("click", onClickAway, true);
    };
  }, []);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const motionContainerOpenMaxHeight = isDesktop ? "auto" : "100%";

  return (
    <ContentContainer isOpen={isOpen}>
      <CollapsableContainerWrap isDesktop={isDesktop}>
        <CollapsableContainerHeader>
          <CollapsableContainerTitleContainer>
            <TitlePreconfiguration />
          </CollapsableContainerTitleContainer>
          <RightItems>
            {!isDesktop && <OtherPreconfigurationsLabel isOpen={isOpen} />}
            <TabNavigationWrapper
              onClick={toggleOpen}
              aria-label={`${t("preconfiguration.numberOthers", {
                count: preconfigurations.length - 1,
              })} ${t("preconfiguration.models")}`}
            >
              <ArrowBorder ref={ctaRef}>
                <StyledArrowDownIcon
                  isOpen={isOpen}
                  width="16px"
                  height="16px"
                />
                <StyledPlusIcon
                  isOpen={isOpen}
                  width="16px"
                  height="16px"
                  viewBox="0 0 20 20"
                />
              </ArrowBorder>
            </TabNavigationWrapper>
          </RightItems>
        </CollapsableContainerHeader>
        <MotionContainerWrap
          ref={dropdownRef}
          isDesktop={isDesktop}
          isOpen={isOpen}
        >
          <MotionContainer
            primaryColor={primaryColor}
            isOpen={isOpen}
            key="accordion"
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            exit="closed"
            variants={{
              open: {
                opacity: 1,
                height: motionContainerOpenMaxHeight,
                display: "block",
              },
              closed: {
                opacity: 0,
                height: 0,
                transitionEnd: { display: "none" },
                transition: { duration: 0.35 },
              },
            }}
          >
            {children({ isOpen })}
          </MotionContainer>
        </MotionContainerWrap>
      </CollapsableContainerWrap>
      {isDesktop && <OtherPreconfigurationsLabel isOpen={isOpen} />}
    </ContentContainer>
  );
};

CollapsableContainerPreconfigurations.propTypes = {
  children: func.isRequired,
  isOpen: bool,
  isDisabled: bool,
  onChange: func,
  defaultOpen: bool,
};

export default CollapsableContainerPreconfigurations;
