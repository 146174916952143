import React, { useState } from "react";
import styled from "styled-components";
import { func, bool, string, oneOf } from "prop-types";
import { AnimatePresence } from "framer-motion";

import ArrowUpIcon from "./Icons/ArrowUp";
import ArrowDownIcon from "./Icons/ArrowDown";
import Typography from "./Typography";

import {
  EVENT_ACTION_MORE_INFO,
  EVENT_ACTION_LESS_INFO,
  EVENT_ACTION_DISPLAY,
} from "@gtm/constants";

const EXPANSION_MODES = {
  ABOVE: "above",
  BELOW: "below",
};

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: ${({ isHeaderString }) => isHeaderString && "space-between"};
  position: relative;
`;

const ExpansionPanel = ({
  defaultOpen = false,
  gtmEvent,
  header,
  icon,
  className,
  content,
  dataId,
  expansionMode = EXPANSION_MODES.BELOW,
}) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const isHeaderString = typeof header === "string";

  const toggleOpen = () => {
    setOpen(!isOpen);
    const eventAction = !isOpen
      ? EVENT_ACTION_MORE_INFO
      : EVENT_ACTION_LESS_INFO;

    gtmEvent(`${EVENT_ACTION_DISPLAY}::${eventAction}`, isOpen);
  };

  const defaultIcon = isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />;
  const toggleIcon = icon ? icon({ isOpen }) : defaultIcon;

  return (
    <Container className={className}>
      <AnimatePresence>
        {isOpen &&
          expansionMode === EXPANSION_MODES.ABOVE &&
          content({ isOpen })}
      </AnimatePresence>
      <Header
        onClick={toggleOpen}
        isHeaderString={isHeaderString}
        data-id={dataId}
      >
        {isHeaderString ? (
          <>
            <Typography>{header}</Typography>
            {toggleIcon}
          </>
        ) : (
          header({ icon: toggleIcon, isOpen })
        )}
      </Header>
      {/* AnimatePresence is sensor NODE catching unmount and mount of component NEVER place it after conditional */}
      {/* @see https://www.framer.com/api/motion/animate-presence/ */}
      <AnimatePresence>
        {isOpen &&
          expansionMode === EXPANSION_MODES.BELOW &&
          content({ isOpen })}
      </AnimatePresence>
    </Container>
  );
};

ExpansionPanel.propTypes = {
  className: string,
  content: func.isRequired,
  dataId: string,
  defaultOpen: bool,
  expansionMode: oneOf(Object.values(EXPANSION_MODES)),
  gtmEvent: func,
  header: func.isRequired,
  icon: func,
};

export default ExpansionPanel;
