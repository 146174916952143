import { useCallback, useEffect, useRef, useState } from "react";

export function useCarousel(options) {
  const { itemsCount, isMounted, rootMarginLeft = 0, rootMarginRight = 0 } =
    options || {};

  const childRefs = useRef([]);
  const containerRef = useRef(null);
  const observerRef = useRef(null);
  const callbackRef = useRef();
  const visibilityDebounce = useRef(null);

  const [pageState, setPageState] = useState({});
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

  useEffect(() => {
    callbackRef.current = entries => {
      const commitObj = {};

      for (const entry of entries) {
        if (entry.target instanceof HTMLElement) {
          const key = entry.target.dataset.page ?? "";
          commitObj[key] = entry.target.parentNode
            ? entry.isIntersecting
            : null;
        }
      }

      setPageState(state => {
        const mergedState = { ...state, ...commitObj };
        const finalState = Object.fromEntries(
          Object.entries(mergedState)
            .filter(item => item[1] !== null)
            .map((item, idx) => [idx + 1, !!item[1]])
        );

        return finalState;
      });
    };
  });

  useEffect(() => {
    observerRef.current?.disconnect();

    if (!containerRef.current) {
      return;
    }

    const observer =
      observerRef.current ??
      new IntersectionObserver(
        entries => {
          callbackRef.current?.(entries);
        },
        {
          root: containerRef.current,
          rootMargin: `0px ${rootMarginRight}px 0px ${rootMarginLeft}px`,
          threshold: 0.9,
        }
      );

    for (const node of childRefs.current) {
      node && observer.observe(node);
    }

    return () => observer.disconnect();
  }, [rootMarginLeft, rootMarginRight, isMounted]);

  const checkScrollbarVisibility = () => {
    visibilityDebounce.current && clearTimeout(visibilityDebounce.current);

    visibilityDebounce.current = setTimeout(() => {
      if (containerRef.current) {
        const { scrollWidth, clientWidth } = containerRef.current;
        setIsScrollbarVisible(scrollWidth > clientWidth);
      }
    }, 100);
  };

  useEffect(() => {
    window.addEventListener("resize", checkScrollbarVisibility);

    return () => {
      window.removeEventListener("resize", checkScrollbarVisibility);
    };
  }, []);

  useEffect(checkScrollbarVisibility, [isMounted]);

  const handleArrowClick = useCallback(diff => {
    if (!containerRef.current) {
      return;
    }

    const itemWidth = childRefs.current
      ?.find(item => item)
      ?.getBoundingClientRect()?.width;

    if (!itemWidth) {
      return;
    }

    containerRef.current.scrollBy({
      behavior: "smooth",
      left: diff * itemWidth,
    });
  }, []);

  const rightPage = itemsCount?.toString() || "";
  const leftPage =
    Object.entries(pageState)
      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
      .reverse()
      .find(([, v]) => v)?.[0] ?? rightPage;
  const initPage = Object.entries(pageState).filter(([, b]) => b).length;

  const isOnTheStart = () => {
    if (!itemsCount) {
      return;
    }

    return Number.parseInt(leftPage) === initPage;
  };

  const isAtTheEnd = () => {
    return leftPage === rightPage;
  };

  return {
    childRefs,
    containerRef,
    pageState,
    handleArrowClick,
    isOnTheStart,
    isAtTheEnd,
    isScrollbarVisible,
  };
}
