import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "@lib/i18n";
import { bool } from "prop-types";
import { produce } from "immer";

import { usePreconfigurationsSelector } from "@redux/reducers/appConfig";
import Typography from "@components/shared/Typography";
import PreconfigurationItem from "./PreconfigurationItem";
import SimpleButton from "@components/shared/SimpleButton";

import { color, fontWeight, fontSize } from "../shared/utils";

const Container = styled.div`
  display: flex;
  justify-items: flex-start;
  flex-direction: column;
  padding: 0 1rem;
`;

const MainPreconfigurationGroup = styled.div`
  width: 100%;
  border-bottom: 1px solid ${color("grey")};
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
`;

const GroupNameTypography = styled(Typography)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0.8rem 0;
  color: ${color("grey")};
  font-weight: ${fontWeight("light")};
  font-size: ${fontSize("sm")};
  white-space: pre-wrap;
`;

const StyledReturnSimpleButton = styled(SimpleButton)`
  margin: 1rem 0 0.5rem 0;
  color: ${color("white")};
  text-align: left;
`;

const SubGroupContainer = styled.div`
  &:last-child {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid white;
  }
`;

const PreconfigurationList = ({ isOpen }) => {
  const { t } = useTranslation();

  const [selectedSubgroup, setSelectedSubgroup] = useState(null);

  const preconfigurations = usePreconfigurationsSelector();

  const targetGroups = preconfigurations.reduce((acc, cur) => {
    if (acc.length === 0) {
      return [[[cur]]];
    }

    const lastGroup = acc[acc.length - 1];
    const lastSubgroup = lastGroup[lastGroup.length - 1];
    const lastItem = lastSubgroup[lastSubgroup.length - 1];

    const { groupLabel, subgroupLabel } = lastItem;

    if (cur.groupLabel !== groupLabel) {
      return produce(acc, draft => {
        draft.push([[cur]]);
      });
    }

    if (cur.subgroupLabel !== subgroupLabel) {
      return produce(acc, draft => {
        draft[acc.length - 1].push([cur]);
      });
    }

    return produce(acc, draft => {
      draft[acc.length - 1][lastGroup.length - 1].push(cur);
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSelectedSubgroup(null);
    }
  }, [isOpen]);

  return (
    <Container data-id="car-PreconfigurationPicker-container">
      {selectedSubgroup ? (
        <SubGroupContainer>
          <StyledReturnSimpleButton
            withArrow
            onClick={() => setSelectedSubgroup(null)}
          >
            {t("preconfiguration.back")}
          </StyledReturnSimpleButton>
          {selectedSubgroup.map((preconfiguration, index) => (
            <PreconfigurationItem
              key={index}
              index={index}
              setSelectedSubgroup={setSelectedSubgroup}
              singlePreconfiguration={preconfiguration}
              label={preconfiguration.label}
            />
          ))}
        </SubGroupContainer>
      ) : (
        <>
          {targetGroups.map((preconfigurationGroups, index) => (
            <MainPreconfigurationGroup key={index}>
              <GroupNameTypography>
                {preconfigurationGroups[0][0].groupLabel}
              </GroupNameTypography>
              {preconfigurationGroups.map((subgroup, index) => (
                <div key={index}>
                  {subgroup.length > 1 ? (
                    <PreconfigurationItem
                      index={index}
                      setSelectedSubgroup={setSelectedSubgroup}
                      label={subgroup[0].subgroupLabel}
                      preconfigurationSubGroup={subgroup}
                    />
                  ) : (
                    <PreconfigurationItem
                      setSelectedSubgroup={setSelectedSubgroup}
                      singlePreconfiguration={subgroup[0]}
                      label={subgroup[0].label}
                    />
                  )}
                </div>
              ))}
            </MainPreconfigurationGroup>
          ))}
        </>
      )}
    </Container>
  );
};

PreconfigurationList.propTypes = { isOpen: bool.isRequired };

export default PreconfigurationList;
