import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import { useAppConfigSelector } from "@redux/reducers/appConfig";

export const useIsStickyTopOrBottom = (
  isStickyToTop,
  isStickyToBottom,
  fixedPositionerRef,
  topOffset,
  bottomOffset = 0
) => {
  const appConfig = useAppConfigSelector();
  if (
    !appConfig.configuration.ui?.orderRequestSticky ||
    (!isStickyToTop && !isStickyToBottom)
  ) {
    return {
      stickToTop: false,
      stickToBottom: false,
    };
  }

  const { height: windowHeight } = useWindowSize();
  const [stickToTop, setStickToTop] = useState(false);
  // use isStickyToBottom as default state to make button sticky on page load
  const [stickToBottom, setStickToBottom] = useState(isStickyToBottom);

  useEffect(() => {
    const handleScroll = () => {
      if (!fixedPositionerRef.current) {
        return;
      }
      const {
        y,
        bottom,
        height,
      } = fixedPositionerRef.current.getBoundingClientRect();

      if (isStickyToTop) {
        setStickToTop(y < topOffset);
      }

      if (isStickyToBottom) {
        setStickToBottom(bottom - bottomOffset - height > windowHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [topOffset, bottomOffset, windowHeight]);

  return { stickToTop, stickToBottom };
};
