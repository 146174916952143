import { useState, useEffect } from "react";

export const useIsFixed = getIsFixed => {
  const [isFixed, setIsFixed] = useState(getIsFixed());

  useEffect(() => {
    const handleScroll = () => {
      const resultGetIsFixed = getIsFixed();

      setIsFixed(resultGetIsFixed);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return isFixed;
};
