import React, { useRef } from "react";
import styled from "styled-components";
import { bool, func, string } from "prop-types";
import { useTranslation } from "@lib/i18n";

import Heading from "./Heading";
import Typography from "./Typography";
import SimpleButton from "./SimpleButton";
import RequestButton from "./RequestButton";
import Modal from "./Modal";
import ButtonGroup from "./ButtonGroup";

import { usePrimaryColor } from "@hooks/usePrimaryColor";

import { smallMobile, tablet, fontSize } from "./utils";

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const StyledRequestButton = styled(RequestButton)`
  margin: 0;
  border: none;
`;

const StyledSimpleButton = styled(SimpleButton)`
  padding: 0 2rem;
`;

const StyledTypography = styled(Typography)`
  padding: 1rem 0;
  text-align: center;

  ${smallMobile`
    text-align: left;
  `}
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  font-size: ${fontSize("lg")};

  ${tablet`
    font-size: ${fontSize("xl")};
  `}
`;

const PreconfigurationModal = ({ isOpen, onConfirm, onCancel, dataId }) => {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  const modalFirstElement = useRef(null);
  const modalLastElement = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      ariaLabel={t("preconfiguration.heading")}
      firstElement={modalFirstElement}
      lastElement={modalLastElement}
      onClose={onCancel}
    >
      <ModalContentContainer data-id={`${dataId}-modal-container`}>
        <StyledHeading headingTag={"h4"}>
          {t("preconfiguration.heading")}
        </StyledHeading>
        <StyledTypography>{t("preconfiguration.description")}</StyledTypography>
        <ButtonGroup>
          <StyledRequestButton
            ref={modalFirstElement}
            withBorder
            inverted
            color={primaryColor}
            triggerRequestCallback={onConfirm}
            data-id={`${dataId}-change-confirm-btn`}
          >
            {t("general.accept")}
          </StyledRequestButton>
          <StyledSimpleButton
            ref={modalLastElement}
            onClick={onCancel}
            data-id={`${dataId}-change-cancel-btn`}
          >
            {t("general.cancel")}
          </StyledSimpleButton>
        </ButtonGroup>
      </ModalContentContainer>
    </Modal>
  );
};

PreconfigurationModal.propTypes = {
  isOpen: bool.isRequired,
  onConfirm: func,
  onCancel: func,
  dataId: string,
};

export default PreconfigurationModal;
