import React from "react";

import BaseIcon from "../BaseIcon";

const YouTubeIcon = props => (
  <BaseIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <polygon points="12,10 32,20 12,30" fill="currentColor"></polygon>
  </BaseIcon>
);

export default YouTubeIcon;
