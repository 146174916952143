import { useCarConfigurationSelector } from "@redux/reducers/deal";

export const useAreOptionsSelected = () => {
  const { accessory, service } = useCarConfigurationSelector();
  const areAccessoriesSelected = accessory.length > 0;
  //service should be in all cases selected max only 1
  const isServiceSelected = service.length > 0;

  return {
    areAccessoriesSelected,
    isServiceSelected,
    areOptionsSelected: areAccessoriesSelected || isServiceSelected,
  };
};
