import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { string, number, object, func, arrayOf } from "prop-types";

import api from "@api";
import gtm from "@gtm/core";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";
import { changeConfigurationEvent } from "@gtm/events/homepage";
import {
  removePromoCodeAction,
  updateCarPreconfigurationAction,
} from "@redux/actions/dealActions";
import { setLastChangedAtAction } from "@redux/actions/deprecatedActions";
import Typography from "@components/shared/Typography";
import SubMenu from "./SubMenu";
import { useDetectDevice } from "@hooks/useDetectDevice";
import { useIsUserTrustedSelector } from "@redux/reducers/auth";
import { withTabNavigation } from "@hoc/withTabNavigation";
import ArrowRightIcon from "@components/shared/Icons/ArrowRight";

import { color, fontWeight, fontSize } from "../shared/utils";
import { sessionStorageClient } from "utils/sessionStorageClient";
import { SESSION_STORAGE_KEYS } from "@shared/constants";

const ItemWrapper = withTabNavigation(styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &:hover > span {
    border-color: ${color("white")};
  }

  &:hover > div > p {
    border-color: ${({ active, theme }) =>
      active ? "transparent" : theme.colors.white};
  }

  &:hover > ul {
    display: flex;
  }

  &:focus-within > ul {
    display: flex;
  }
`);

const Color = styled.span`
  width: 1.3rem;
  height: 1.3rem;
  margin: 0.5rem 0;
  position: relative;
  display: flex;
  cursor: pointer;
  transition: 0.1s ease-in-out all;
`;

const Selector = styled.div`
  width: 100%;
  height: 4px;
  background: ${color("white")};
  opacity: ${({ active }) => active || 0};
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${color("grey1")};
  box-shadow: 0 0 0 2px
    ${({ active, theme }) => theme.colors[active ? "white" : "grey1"]};
`;

const Label = styled.div`
  margin: 0 1rem;
`;

const StyledTypography = styled(Typography)`
  color: ${color("white")};
  font-weight: ${fontWeight("bolder")};
  font-size: ${fontSize("lg")};
  border-bottom: 2px solid transparent;
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  color: ${color("white")};
`;

const MiddleSpaceF = styled.div`
  flex: 1;
`;

const PreconfigurationItem = ({
  singlePreconfiguration,
  preconfigurationSubGroup,
  setSelectedSubgroup,
  label,
  index = 0,
}) => {
  const dispatch = useDispatch();
  const { isDesktop } = useDetectDevice();
  const isUserTrusted = useIsUserTrustedSelector();

  const {
    id: activePreconfigurationId,
  } = useActiveCarPreconfigurationSelector();

  const assetUrl = preconfigurationSubGroup
    ? `/images/preconfigs/picker_colors.svg`
    : `/images/preconfigs/${singlePreconfiguration.id}/picker.svg`;

  const onPreconfigurationChange = preconfiguration => {
    if (preconfiguration.id === activePreconfigurationId) {
      return;
    }

    if (!isUserTrusted) {
      api.removePromoCodeFromSession();
    }

    dispatch(removePromoCodeAction());
    dispatch(updateCarPreconfigurationAction(preconfiguration));
    dispatch(setLastChangedAtAction(Date.now()));

    sessionStorageClient.removeItem(SESSION_STORAGE_KEYS.PROMOCODE);

    gtm.fire(changeConfigurationEvent(preconfiguration.label));
  };

  const isSubGroupActive = preconfigurationSubGroup?.some(
    p => p.id === activePreconfigurationId
  );

  const preconfigurationCount = preconfigurationSubGroup?.length;

  const isItemWithSubmenu = !!preconfigurationSubGroup;

  const isItemActive =
    activePreconfigurationId === singlePreconfiguration?.id || isSubGroupActive;

  const onClickItem = () => {
    if (!isItemWithSubmenu) {
      onPreconfigurationChange(singlePreconfiguration);

      return;
    }

    if (isDesktop) {
      return;
    }

    setSelectedSubgroup(preconfigurationSubGroup);
  };

  return (
    <ItemWrapper onClick={onClickItem} active={isItemActive}>
      <Color data-id={`preconfiguration-color-${index}`}>
        <StyledImg
          src={getStaticAssetURL(assetUrl)}
          alt={label}
          title={label}
          active={isItemActive}
        />
      </Color>
      <Label>
        <StyledTypography active={isItemActive}>
          {label}
          {preconfigurationCount && ` (${preconfigurationCount})`}
        </StyledTypography>

        <Selector
          active={
            activePreconfigurationId === singlePreconfiguration?.id ||
            isSubGroupActive
          }
        />
      </Label>
      {isItemWithSubmenu && (
        <>
          <MiddleSpaceF />
          <StyledArrowRightIcon width="16px" height="16px" />
          <SubMenu preconfigurationSubGroup={preconfigurationSubGroup} />
        </>
      )}
    </ItemWrapper>
  );
};

PreconfigurationItem.propTypes = {
  label: string.isRequired,
  setSelectedSubgroup: func,
  singlePreconfiguration: object,
  index: number,
  preconfigurationSubGroup: arrayOf(object),
};

export default PreconfigurationItem;
