import React from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";

import { useTranslation } from "@lib/i18n";

import Popin from "@components/shared/Popin";

const StyledTypography = styled.div`
  margin-bottom: 2rem;
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`;

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const translationValue = t(`customize.confirmationDialog.body`, {
    returnObjects: true,
  });

  const paragraphs = Array.isArray(translationValue) ? translationValue : [];

  if (!isOpen) {
    return null;
  }

  return (
    <Popin
      withCloseIcon
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmTitle={t("general.accept")}
      title={t("customize.confirmationDialog.title")}
      preventConfirmBeforeScroll
    >
      {paragraphs.map((paragraph, i) => (
        <StyledTypography key={i}>
          <StyledTitle>{paragraph.heading}</StyledTitle>
          {paragraph.text}
        </StyledTypography>
      ))}
    </Popin>
  );
};

ConfirmationModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
};

export default ConfirmationModal;
