import React from "react";
import styled, { css } from "styled-components";
import { string, bool } from "prop-types";

import { color } from "./utils";

const StyledDivider = styled.hr`
  background: ${color("grey")};
  border: none;
  height: ${({ vertical }) => (vertical ? "unset" : "0.125rem")};
  width: ${({ vertical }) => (vertical ? "0.125rem" : "100%")};

  ${({ spacing }) =>
    spacing &&
    css`
      margin-top: ${spacing};
      margin-bottom: ${spacing};
    `}
`;

const Divider = ({ vertical = false, ...props }) => (
  <StyledDivider vertical={vertical} {...props} />
);

Divider.propTypes = {
  spacing: string,
  vertical: bool,
};

export default Divider;
