import React, { useState } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { func, bool } from "prop-types";
import getConfig from "next/config";

import PreconfigurationList from "../PreconfigurationPicker/PreconfigurationList";
import DesktopContainer from "@components/shared/DesktopContainer";
import PriceDetail from "@components/PriceDetail/PriceDetail";
import CompareButtonContainer from "@components/CompareButtonContainer/CompareButtonContainer";
import CollapsableContainerPreconfigurations from "./CollapsableContainerPreconfigurations";

import { useGetPriceDetailProps } from "@hooks/useGetPriceDetailProps";
import { useIsCashJourneySelected } from "@hooks/useIsCashJourneySelected";
import { color, desktop } from "../shared/utils";
import { COUNTRIES } from "@shared/constants";
import { useCurrencySettingsSelector } from "@redux/reducers/appConfig";
import { useSelectedJourneySelector } from "@redux/reducers/deal";
import { ROUTES } from "@shared/routing/routes";
import { FIXED_ELEMENTS_ATTRIBUTE_NAME } from "@hooks/useScrollToView";
import LegalRequirementPopIn from "@components/LegalRequirementPopIn/LegalRequirementPopIn";
import { useIsVACJourneySelected } from "hooks/useIsVACJourneySelected";
import { useCashVehiclePrice } from "../../hooks/useCashVehiclePrice";

const Container = styled.div`
  width: 100%;
  display: flex;
  background: ${color("black1")};
  transition-duration: 0.6s;

  ${desktop`
    height: 7rem;
    position: fixed;
    top: 7.5rem;
    left: 0;
    right: 0;
    z-index: 999;
  `}
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.contentWidth};
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

const StyledPriceDetail = styled(PriceDetail)`
  width: 100%;

  ${desktop`
    width: 38rem;
  `}
`;

const PreconfigurationPickerDesktopContainer = styled(DesktopContainer)`
  flex: 1;
  margin-left: 2rem;
`;

const { publicRuntimeConfig } = getConfig();
const { COUNTRY } = publicRuntimeConfig;

const CarouselAndFinanceBar = ({
  onIsFinancingOpenChange,
  isPromoCodeApplicable,
}) => {
  const isCashJourneySelected = useIsCashJourneySelected();
  const isVACJourneySelected = useIsVACJourneySelected();

  const journey = useSelectedJourneySelector();

  const currencySettings = useCurrencySettingsSelector();

  const router = useRouter();

  const containerAttributes = {
    [FIXED_ELEMENTS_ATTRIBUTE_NAME]: router.pathname === ROUTES.CONFIG,
  };

  const [
    isLegalRequirementsDismissed,
    setIsLegalRequirementsDismissed,
  ] = useState(false);

  const shouldShowLegalRequirements =
    isVACJourneySelected &&
    COUNTRY === COUNTRIES.BE &&
    !isLegalRequirementsDismissed;

  const {
    amount,
    isLeasingRequestLoading,
    isFinanceRequestError,
    withFinanceWidgetButton,
    withEcoBonus,
    deposit,
    teaser,
    isPersonalisedVACOffer,
  } = useGetPriceDetailProps(isPromoCodeApplicable);

  const total = useCashVehiclePrice(isPromoCodeApplicable);

  return (
    <Container {...containerAttributes}>
      <ContentContainer>
        <PreconfigurationPickerDesktopContainer>
          <CollapsableContainerPreconfigurations>
            {({ isOpen }) => (
              <>
                <PreconfigurationList isOpen={isOpen} />
                <CompareButtonContainer />
              </>
            )}
          </CollapsableContainerPreconfigurations>
        </PreconfigurationPickerDesktopContainer>
        <StyledPriceDetail
          withDeliveryInfo
          journey={journey}
          withParallelograms={isCashJourneySelected}
          isFinanceRequestError={isFinanceRequestError}
          isLoading={isLeasingRequestLoading}
          withJourneySwitch
          withFinanceWidgetButton={withFinanceWidgetButton}
          withEcoBonus={withEcoBonus}
          amount={amount}
          total={total}
          teaser={teaser}
          deposit={deposit}
          priceFormat={currencySettings.format}
          currencySymbol={currencySettings.symbol}
          onIsFinancingOpenChange={onIsFinancingOpenChange}
          isPersonalisedVACOffer={isPersonalisedVACOffer}
          isQuantityVisible={false}
        />
      </ContentContainer>
      {shouldShowLegalRequirements && (
        <LegalRequirementPopIn
          onClose={() => {
            setIsLegalRequirementsDismissed(true);
          }}
          isLoading={isLeasingRequestLoading}
        />
      )}
    </Container>
  );
};

CarouselAndFinanceBar.propTypes = {
  onIsFinancingOpenChange: func.isRequired,
  isPromoCodeApplicable: bool,
};

export default CarouselAndFinanceBar;
