import React, { useRef } from "react";
import styled from "styled-components";
import { bool, func, array } from "prop-types";
import { useTranslation } from "@lib/i18n";

import Heading from "@components/shared/Heading";
import Typography from "@components/shared/Typography";
import SimpleButton from "@components/shared/SimpleButton";
import RequestButton from "@components/shared/RequestButton";
import Modal from "@components/shared/Modal";
import ButtonGroup from "@components/shared/ButtonGroup";

import { usePrimaryColor } from "@hooks/usePrimaryColor";

import { smallMobile, tablet, fontSize } from "@components/shared/utils";

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const StyledRequestButton = styled(RequestButton)`
  margin: 0;
  border: none;
`;

const StyledSimpleButton = styled(SimpleButton)`
  padding: 0 2rem;
`;

const StyledTypography = styled(Typography)`
  padding: 1rem 0;
  text-align: center;

  ${smallMobile`
    text-align: left;
  `}
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  font-size: ${fontSize("lg")};

  ${tablet`
    font-size: ${fontSize("xl")};
  `}
`;

const UnavailableItemsModal = ({
  isOpen,
  unavailableItems,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  const modalFirstElement = useRef(null);
  const modalLastElement = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      firstElement={modalFirstElement}
      lastElement={modalLastElement}
      ariaLabel={t("preconfiguration.heading")}
      onClose={onCancel}
    >
      <ModalContentContainer data-id={"unavailable-items-modal-container"}>
        <StyledHeading headingTag={"h4"}>
          {t("preconfiguration.heading")}
        </StyledHeading>
        <StyledTypography>
          {t("basket.errorMessages.optionsUnavailable")}
        </StyledTypography>
        {!!unavailableItems?.length && (
          <StyledTypography>
            {unavailableItems.map(item => item.label).join(", ")}
          </StyledTypography>
        )}
        <ButtonGroup>
          <StyledRequestButton
            ref={modalFirstElement}
            withBorder
            inverted
            color={primaryColor}
            triggerRequestCallback={onConfirm}
            data-id={"unavailable-items-modal-change-confirm-btn"}
          >
            {t("general.accept")}
          </StyledRequestButton>
          <StyledSimpleButton
            ref={modalLastElement}
            onClick={onCancel}
            data-id={"unavailable-items-modal-change-cancel-btn"}
          >
            {t("general.cancel")}
          </StyledSimpleButton>
        </ButtonGroup>
      </ModalContentContainer>
    </Modal>
  );
};

UnavailableItemsModal.propTypes = {
  isOpen: bool.isRequired,
  unavailableItems: array.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
};

export default UnavailableItemsModal;
