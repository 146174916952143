import React from "react";
import styled, { css } from "styled-components";
import { node, bool } from "prop-types";
import { useIsMounted } from "hooks/useIsMounted";
import { useCarousel } from "hooks/useCarousel";
import { usePrimaryColor } from "hooks/usePrimaryColor";
import ArrowLeftIcon from "./Icons/ArrowLeft";
import ArrowRightIcon from "./Icons/ArrowRight";
import { tablet } from "./utils";

const SContainer = styled.div`
  align-items: stretch;
  display: flex;
  gap: 1rem;
  height: 100%;
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
  padding-bottom: 0.5rem;
  width: 100%;

  &::before,
  after {
    content: "";
    flex-shrink: 0;
    width: 0;
    height: 1px;
    pointer-events: none;
  }

  &::before {
    scroll-snap-align: start;
    margin-right: -1rem;
  }

  &::after {
    scroll-snap-align: end;
    margin-left: -1rem;
  }

  &::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.grey};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grey1};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;

const SItem = styled.div`
  ${({ notFirstOrLast }) =>
    notFirstOrLast &&
    css`
      scroll-snap-align: start;
    `}

  ${({ singleItem }) =>
    singleItem &&
    css`
      width: 100%;
    `}
`;

const SWrap = styled.div`
  margin-bottom: 1.25rem;
  position: relative;
  width: 100%;
`;

const SButtonsWrap = styled.div`
  display: none;

  ${tablet`
    display: flex;
    gap: 0.5rem;
    padding-top: 0.5rem;
  `}
`;

const SButton = styled.button`
  appearance: none;
  background: transparent;
  border-radius: 50%;
  border: 0;
  color: ${({ theme }) => theme.colors.grey1};
  cursor: pointer;
  display: grid;
  height: 2rem;
  padding: 0;
  place-items: center;
  text-align: center;
  top: 50%;
  user-select: none;
  width: 2rem;

  &:hover {
    color: ${({ currentColor }) => currentColor};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.grey};
    pointer-events: none;
  }
`;

const ScrollableContainer = ({ children: _children, isVisible = true }) => {
  const children = _children.filter(Boolean);

  const currentColor = usePrimaryColor();
  const isMounted = useIsMounted();

  const {
    childRefs,
    containerRef,
    handleArrowClick,
    isAtTheEnd,
    isOnTheStart,
    isScrollbarVisible,
  } = useCarousel({
    itemsCount: children.length,
    isMounted: isMounted && isVisible,
  });

  return (
    <SWrap>
      <SContainer ref={containerRef}>
        {children.map((child, idx) => (
          <SItem
            singleItem={children.length === 1}
            notFirstOrLast={idx !== 0 && idx !== children.length - 1}
            key={idx}
            ref={node => {
              if (node) {
                childRefs.current[idx] = node;
                node.dataset.page = `${idx + 1}`;
              }
            }}
          >
            {child}
          </SItem>
        ))}
      </SContainer>
      {children.length > 1 && isScrollbarVisible && (
        <SButtonsWrap>
          <SButton
            currentColor={currentColor}
            onClick={() => handleArrowClick(-1)}
            disabled={isOnTheStart()}
          >
            <ArrowLeftIcon />
          </SButton>
          <SButton
            currentColor={currentColor}
            onClick={() => handleArrowClick(1)}
            disabled={isAtTheEnd()}
          >
            <ArrowRightIcon />
          </SButton>
        </SButtonsWrap>
      )}
    </SWrap>
  );
};

ScrollableContainer.propTypes = {
  children: node.isRequired,
  isDisabled: bool,
  isVisible: bool,
};

export default ScrollableContainer;
