import React from "react";
import styled from "styled-components";

import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";
import Typography from "@components/shared/Typography";
import { color, fontWeight, fontSize, desktop } from "../shared/utils";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const Color = styled.div`
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  position: relative;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.1s ease-in-out all;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const StyledTypography = styled(Typography)`
  color: ${color("white")};
  font-weight: ${fontWeight("bolder")};
  font-size: ${fontSize("lg")};
  margin: 0 0.5rem;

  ${desktop`
    margin: 0 2rem;
    font-weight: ${fontWeight("bolder")};
    font-size: ${fontSize("xl")};
  `}
`;

const ChosenPreconfiguration = () => {
  const activePreConfiguration = useActiveCarPreconfigurationSelector();

  return (
    <Container>
      <Color>
        <StyledImg
          src={getStaticAssetURL(
            `/images/preconfigs/${activePreConfiguration.id}/picker.svg`
          )}
          alt={activePreConfiguration.label}
          title={activePreConfiguration.label}
        />
      </Color>
      <StyledTypography forwardedAs="h1">
        {activePreConfiguration.label}
      </StyledTypography>
    </Container>
  );
};

export default ChosenPreconfiguration;
