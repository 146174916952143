import React from "react";
import styled from "styled-components";
import { useTranslation } from "@lib/i18n";

import Typography from "../shared/Typography";
import {
  color,
  fontSize,
  largeMobile,
  desktop,
  font,
  fontWeight,
} from "../shared/utils";
import { ROUTES } from "@shared/routing/routes";

import Link from "next/link";
import { usePrimaryColor } from "hooks/usePrimaryColor";
import { useRoutingUtils } from "@hooks/useRoutingUtils";
import { useTestDriveLinkOnHomePagePathSelector } from "@redux/reducers/appConfig";

import gtm from "@gtm/core";
import { linkTestDriveClickEvent } from "@gtm/events/homepage";

const SContainer = styled.div`
  align-items: center;
  background: ${color("black1")};
  color: ${color("black")};
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
  justify-content: center;
  padding: 2rem;
  text-align: center;

  ${desktop`
    flex-direction: row;
    height: 10rem;
  `}
`;

const SContentColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  width: 100%;

  ${desktop`
    flex-direction: column;
    width: unset;
  `}
`;

const STypography = styled(Typography)`
  font-size: ${fontSize("md")};
  color: ${color("white")};

  ${largeMobile`
    font-size: ${fontSize("lg")}
  `}
`;

const SLinkButton = styled.span`
  align-items: center;
  background: ${color("white")};
  border: solid 2px ${color("white")};
  border-radius: 32px;
  color: ${({ primaryColor }) => primaryColor};
  cursor: pointer;
  display: flex !important;
  font-family: ${font("citroen")};
  font-size: ${fontSize("default")};
  font-weight: ${fontWeight("bold")};
  justify-content: center;
  margin: 0.5rem 0;
  min-width: 12.5rem;
  padding: 1.25rem 2rem;
  position: relative;
  text-transform: lowercase;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: ${({ primaryColor }) => primaryColor};
    border-color: ${color("white")};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const TestDriveLink = () => {
  const { t, i18n } = useTranslation();
  const routingUtils = useRoutingUtils();
  const primaryColor = usePrimaryColor();
  const testDriveLinkPath = useTestDriveLinkOnHomePagePathSelector();

  const onTestDriveClick = path => {
    gtm.fire(
      linkTestDriveClickEvent({
        label: path,
      })
    );
  };

  return (
    <SContainer>
      <SContentColumn>
        <STypography>{t("testDrive.bookTestDrive")}</STypography>
      </SContentColumn>
      <SContentColumn>
        {testDriveLinkPath ? (
          <a
            href={testDriveLinkPath}
            target="_blank"
            rel="noreferrer"
            onClick={() => onTestDriveClick(testDriveLinkPath)}
          >
            <SLinkButton primaryColor={primaryColor}>
              {t("testDrive.discoveryJourney")}
            </SLinkButton>
          </a>
        ) : (
          <Link
            href={ROUTES.TEST_DRIVE_INDEX}
            as={routingUtils.getLocalizedPathname(
              ROUTES.TEST_DRIVE_INDEX,
              null,
              i18n.language
            )}
            target="_blank"
            rel="noreferrer"
          >
            <a
              onClick={() => onTestDriveClick(ROUTES.TEST_DRIVE_INDEX)}
              target="_blank"
            >
              <SLinkButton primaryColor={primaryColor}>
                {t("testDrive.discoveryJourney")}
              </SLinkButton>
            </a>
          </Link>
        )}
      </SContentColumn>
    </SContainer>
  );
};

TestDriveLink.propTypes = {};

export default TestDriveLink;
