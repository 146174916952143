import React, { useEffect, useRef } from "react";
import { node, string, bool, func, number } from "prop-types";
import styled from "styled-components";
import NukaCarousel from "nuka-carousel";

import { useIsInitialRender } from "@hooks/useIsInitialRender";

import { desktop } from "@components/shared/utils";

const CarouselWrapper = styled.div`
  display: block;
  height: 100%;
  padding-left: 6vw;
  position: relative;
  visibility: ${({ isHidden }) => isHidden && "hidden"};

  ${desktop`
    height: 100%;
    overflow: hidden;
    padding-left: 0;
    width: 100%;
    background: white;
    border-radius: 0.4rem;
  `}

  /* override 3rd party lib mount glitch caused by height auto */
  .slider-slide {
    outline: none;

    ${desktop`
      height: 100%;
    `}
  }

  .slider-list {
    ${desktop`
      height: 100%;
    `}
  }

  .slider-frame {
    background: ${({ theme, isInitialRender }) =>
      isInitialRender && theme.colors.white};
  }
`;

const FallbackContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Carousel = ({
  children,
  className,
  afterSlide,
  currentIndex,
  isHidden = false,
}) => {
  const isInitialRender = useIsInitialRender();

  const ref = useRef();

  useEffect(() => {
    ref.current = currentIndex;
  }, [currentIndex]);

  return (
    <CarouselWrapper
      className={className}
      isInitialRender={isInitialRender}
      isHidden={isHidden}
    >
      {isInitialRender ? (
        <FallbackContainer>{children[currentIndex]}</FallbackContainer>
      ) : (
        <NukaCarousel
          heightMode="current"
          slideIndex={currentIndex}
          afterSlide={afterSlide}
          cellSpacing={-5}
          frameOverflow="visible"
          withoutControls
          swiping
        >
          {children}
        </NukaCarousel>
      )}
    </CarouselWrapper>
  );
};

Carousel.propTypes = {
  children: node.isRequired,
  afterSlide: func.isRequired,
  shouldWrapAround: bool,
  className: string,
  currentIndex: number,
  isHidden: bool,
};

export default Carousel;
