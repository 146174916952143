import { useGetPriceDetailProps } from "@hooks/useGetPriceDetailProps";
import { useIsCashJourneySelected } from "@hooks/useIsCashJourneySelected";

export const useIsAllowedToContinueWithOrder = (
  isPromoCodeApplicable = false
) => {
  const isCashJourneySelected = useIsCashJourneySelected();
  const {
    isLeasingRequestLoading,
    isFinanceRequestError,
  } = useGetPriceDetailProps(isPromoCodeApplicable);

  return (
    isCashJourneySelected ||
    (!isLeasingRequestLoading && !isFinanceRequestError)
  );
};
