import getConfig from "next/config";
import React, { memo, useEffect, useRef, useState } from "react";
import { useToggle } from "react-use";
import styled, { useTheme } from "styled-components";

import ErrorBoundary from "../shared/ErrorBoundary";
import Subheading from "../shared/Subheading";
import Switch from "../shared/Switch";
import Typography from "../shared/Typography";
import {
  color,
  tabletLarge,
  desktop,
  fontSize,
  fontWeight,
  font,
} from "../shared/utils";

import gtm from "@gtm/core";
import { changeModelViewEvent } from "@gtm/events/homepage";

import { usePrimaryColor } from "@hooks/usePrimaryColor";

import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { useTranslation } from "@lib/i18n";

import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";

const { publicRuntimeConfig } = getConfig();
const { BUSINESS_ENV } = publicRuntimeConfig;

const WIDGET_STATE = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
};

const WIDGET_EVENT = {
  LOADING_START: "360HasStartedLoading",
  LOADING_COMPLETE: "360HasCompletedLoading",
};

const WIDGET_ID = "360Widget";

const SContainer = styled.div`
  background-color: ${color("white")};
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  height: 88vw;
  justify-content: space-between;
  max-height: 35rem;
  max-width: 40rem;
  padding: 2rem 0 1rem;
  position: relative;
  overflow: hidden;
  width: 88vw;

  ${tabletLarge`
    height: 100%;
    width: 100%;
  `}

  ${desktop`
    border-radius: 0.4rem;
  `}
`;

const SIFrame = styled.iframe`
  background: ${color("white")};
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const SHeading = styled(Subheading)`
  font-family: ${font("citroen")};
  font-size: ${fontSize("xl")};
  font-weight: ${fontWeight("bolder")};
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 2px;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 1;

  ${desktop`
    font-size: ${fontSize("xxl")};
  `}
`;

const SSwitcherWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.8rem 0;
  width: 100%;
  z-index: 1;
`;

const STypography = styled(Typography)`
  text-shadow: rgba(0, 0, 0, 0.1) 0 0 2px;
  transition: color 0.2s ease-in;

  &:first-of-type {
    margin-right: 1.4rem;
  }

  &:last-of-type {
    margin-left: 1.4rem;
  }
`;

const ThreeSixty = () => {
  const [widgetState, setWidgetState] = useState(WIDGET_STATE.INITIAL);
  const [exterior, toggleExterior] = useToggle(true);

  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();
  const theme = useTheme();
  const activePreConfiguration = useActiveCarPreconfigurationSelector();

  const widgetRef = useRef(null);

  const setViewpoint = () => {
    widgetRef.current?.contentWindow?.setViewpoint?.(exterior);
  };

  const onExteriorToggle = () => {
    toggleExterior();
    gtm.fire(
      changeModelViewEvent({
        action: exterior ? "interieur" : "exterieur",
        label: t(`carGallery.toggleView.${exterior ? "interior" : "exterior"}`),
      })
    );
  };

  const setPreconfiguration = () => {
    if (widgetState !== WIDGET_STATE.LOADED) {
      BUSINESS_ENV !== "production" &&
        console.warn(
          `Attempted to change configuration before 360 has finished loading. This could cause an error!`
        );
      return;
    }
    widgetRef.current?.contentWindow?.setPreconfiguration?.(
      activePreConfiguration.id360View
    );
  };

  useEffect(() => {
    const handleLoadingStart = () => {
      setWidgetState(WIDGET_STATE.LOADING);
    };

    const handleLoadingComplete = () => {
      setWidgetState(WIDGET_STATE.LOADED);
    };

    window.document.addEventListener(
      WIDGET_EVENT.LOADING_START,
      handleLoadingStart,
      false
    );
    window.document.addEventListener(
      WIDGET_EVENT.LOADING_COMPLETE,
      handleLoadingComplete,
      false
    );

    return () => {
      window.document.removeEventListener(
        WIDGET_EVENT.LOADING_START,
        handleLoadingStart,
        false
      );
      window.document.removeEventListener(
        WIDGET_EVENT.LOADING_COMPLETE,
        handleLoadingComplete,
        false
      );
    };
  }, []);

  useEffect(() => {
    if (widgetState === WIDGET_STATE.LOADED) {
      setPreconfiguration();
    }
  }, [widgetState]);

  useEffect(() => {
    if (widgetState === WIDGET_STATE.LOADED) {
      setViewpoint();
    }
  }, [exterior]);

  return (
    <SContainer>
      <SHeading customColor={primaryColor} align="center">
        {activePreConfiguration.label ?? ""}
      </SHeading>
      <ErrorBoundary id="frame">
        <SIFrame
          allowFullScreen
          frameborder="0"
          id={WIDGET_ID}
          name={WIDGET_ID}
          onmousewheel=""
          primaryColor={primaryColor}
          ref={widgetRef}
          // Don't use CDN, so that the Same-origin policy
          // does not prevent js access to the iframe
          src={getStaticAssetURL("/360/index.html", { useCdn: false })}
        />
      </ErrorBoundary>
      {widgetState === WIDGET_STATE.LOADED && (
        <SSwitcherWrapper data-id="360-toggle-view-switch-container">
          <STypography
            bold
            customColor={exterior ? primaryColor : theme.colors.grey}
          >
            {t("carGallery.toggleView.exterior")}
          </STypography>
          <Switch
            checked={!exterior}
            data-id="360-toggle-view-button"
            isHorizontal
            onChange={onExteriorToggle}
            primaryColor={primaryColor}
            switchBackgroundColor={theme.colors.transparent}
            switchBorderColor={theme.colors.grey}
          />
          <STypography
            bold
            customColor={!exterior ? primaryColor : theme.colors.grey}
          >
            {t("carGallery.toggleView.interior")}
          </STypography>
        </SSwitcherWrapper>
      )}
    </SContainer>
  );
};

ThreeSixty.propTypes = {};

export default memo(ThreeSixty);
