import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import { node, bool, func, number } from "prop-types";
import { motion } from "framer-motion";

import ArrowDownIcon from "@components/shared/Icons/ArrowDown";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { withTabNavigation } from "@hoc/withTabNavigation";

import {
  fontWeight,
  font,
  fontSize,
  color,
  desktop,
} from "@components/shared/utils";

const ContentContainer = styled.div`
  background: ${({ isOpen, theme }) => isOpen && theme.colors.white};
  border-radius: 0.4rem;
  padding: 2rem;
  transition: background-color 0.3s ease-in-out;
`;

export const CollapsableContainerHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${color("white")};
  cursor: ${({ isDisabled }) => !isDisabled && "pointer"};
  display: flex;
  font-size: ${fontSize("lg")};
  justify-content: space-between;
  padding-bottom: 0.5rem 0 0.75rem;
  transition: color 0.2s linear;

  color: ${({ isOpen, isDisabled, theme, primaryColor }) => {
    if (isOpen) {
      return primaryColor;
    }

    if (isDisabled) {
      return theme.colors.grey;
    }

    return theme.colors.white;
  }};

  &:hover {
    color: ${({ isOpen, isDisabled, theme }) =>
      !isOpen && !isDisabled && theme.colors.black};
  }
`;

export const CollapsableContainerHeaderWithTabNavigation = withTabNavigation(
  CollapsableContainerHeader
);

export const CollapsableContainerTitleContainer = styled.div`
  color: inherit;
  font-family: ${font("citroen")};
  font-size: inherit;
  font-weight: ${fontWeight("bold")};
  letter-spacing: 0.5px;
  margin: 0.5rem 0;
  text-transform: lowercase;
  user-select: none;
  width: 100%;
`;

export const CollapsableContainerSubTitle = styled.div`
  color: inherit;
  font-family: ${font("citroen")};
  font-size: inherit;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
`;

const HeaderTitleIndex = styled.span`
  ${desktop`
    display: none;
  `}
`;

const StyledArrowDownIcon = styled(ArrowDownIcon)`
  transform: ${({ isOpen }) => isOpen && "rotate(180deg)"};
`;

const MotionContainer = styled(motion.div)`
  overflow-y: hidden;
  padding: ${({ isOpen }) => isOpen && "1rem 0 0 0"};
`;

const CollapsableContainer = forwardRef(
  (
    {
      children,
      titleIndex,
      title,
      isOpen,
      isDisabled,
      onChange,
      titleAsHeader = false,
      subTitle = null,
      defaultOpen = false,
      ...props
    },
    ref
  ) => {
    const isControlled = isOpen !== undefined;
    const primaryColor = usePrimaryColor();
    const [internalIsOpen, setInternalIsOpen] = useState(defaultOpen);

    const currentIsOpen = isControlled ? isOpen : internalIsOpen;

    const onHeaderContainerClick = () => {
      if (isDisabled) {
        return;
      }

      onChange?.(currentIsOpen);

      if (!isControlled) {
        setInternalIsOpen(!currentIsOpen);
      }
    };

    return (
      <ContentContainer isOpen={currentIsOpen} ref={ref} {...props}>
        <CollapsableContainerHeaderWithTabNavigation
          isOpen={currentIsOpen}
          isDisabled={isDisabled}
          primaryColor={primaryColor}
          onClick={onHeaderContainerClick}
        >
          <CollapsableContainerTitleContainer as={titleAsHeader && "h3"}>
            {titleIndex && <HeaderTitleIndex>{titleIndex}. </HeaderTitleIndex>}
            {title}
          </CollapsableContainerTitleContainer>
          {!isDisabled && (
            <StyledArrowDownIcon
              isOpen={currentIsOpen}
              width="16px"
              height="16px"
            />
          )}
        </CollapsableContainerHeaderWithTabNavigation>
        {subTitle && (
          <CollapsableContainerSubTitle>
            {subTitle}
          </CollapsableContainerSubTitle>
        )}
        <MotionContainer
          isOpen={currentIsOpen}
          key="accordion"
          animate={currentIsOpen ? "open" : "closed"}
          initial={currentIsOpen ? "open" : "closed"}
          exit="closed"
          variants={{
            open: {
              opacity: 1,
              height: "auto",
            },
            closed: {
              opacity: 0,
              height: 0,
              transitionEnd: { display: "none" },
              transition: { duration: 0.3, type: "tween" },
            },
          }}
        >
          {children}
        </MotionContainer>
      </ContentContainer>
    );
  }
);

CollapsableContainer.displayName = "CollapsableContainer";

CollapsableContainer.propTypes = {
  children: node.isRequired,
  title: node.isRequired,
  subTitle: node,
  isOpen: bool,
  isDisabled: bool,
  titleIndex: number,
  onChange: func,
  defaultOpen: bool,
  titleAsHeader: bool,
};

export default CollapsableContainer;
