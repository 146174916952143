import React from "react";
import { func, array } from "prop-types";
import { useTranslation } from "@lib/i18n";
import styled from "styled-components";

import Typography from "@components/shared/Typography";
import RequestButton from "@components/shared/RequestButton";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { useGetFormattedPrice } from "@hooks/useGetFormattedPrice";
import { useVehicleQuantitySelector } from "@redux/reducers/deal";
import { priceAlgorithm } from "@lib/priceAlgorithm";

import {
  prop,
  color,
  font,
  fontSize,
  fontWeight,
  hyphenate,
  lineHeight,
} from "@components/shared/utils";

const Container = styled.div`
  width: 100%;
  padding-bottom: 1.5rem;
`;

const ButtonContainer = styled.div`
  padding: 2rem 0;
  position: relative;
  display: flex;
  align-items: center;
  color: ${color("black")};
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  padding: 1rem;
`;

const ListItem = styled.li`
  margin-left: 0.5rem;
  color: ${prop("primaryColor")};
`;

const CustomizeIncludeTitle = styled(Typography)`
  text-transform: uppercase;
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
`;

const Service = styled.div`
  ${({ theme, isLastService }) =>
    !isLastService &&
    ` border-bottom: 1px solid ${theme.colors.grey};
  margin-bottom: 2rem;`}
`;

const StyledRequestButton = styled(RequestButton)`
  font-weight: ${fontWeight("bold")};
  min-width: 6rem;
  padding: 1rem 2rem;
`;

const Price = styled(Typography)`
  font-family: ${font("citroen")};
  font-size: ${fontSize("xxl")};
  font-weight: ${fontWeight("bold")};
  text-transform: uppercase;
`;

const SpacedTypography = styled(Typography)`
  line-height: ${lineHeight("larger")};
  font-size: ${fontSize("sm")};
`;

const ServicesDetail = ({
  onServiceButtonClick,
  selectedServices,
  availableServices,
}) => {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();
  const getFormattedPrice = useGetFormattedPrice();
  const quantity = useVehicleQuantitySelector();

  return (
    <Container>
      {availableServices.map(service => {
        const isServiceSelected = selectedServices.find(
          item => item.id === service.id
        );

        return (
          <Service
            data-id="customize-include"
            key={service.id}
            isLastService={
              availableServices[availableServices.length - 1] === service
            }
          >
            <TextContainer>
              <CustomizeIncludeTitle
                data-id={`customize-include-item-name-${hyphenate(
                  service.label
                )}`}
                margin="0.5rem 0"
              >
                {service.label}
              </CustomizeIncludeTitle>
            </TextContainer>
            <ButtonContainer primaryColor={primaryColor}>
              <StyledRequestButton
                withBorder
                data-id={`customize-${
                  isServiceSelected ? "remove" : "add"
                }-package`}
                triggerRequestCallback={() => onServiceButtonClick(service)}
              >
                {isServiceSelected ? t("customize.remove") : t("customize.add")}
              </StyledRequestButton>
              <Price>{`${t("mathSymbols.plus")}${getFormattedPrice(
                priceAlgorithm.times(service.netPrice, quantity)
              )}`}</Price>
            </ButtonContainer>
            <SpacedTypography>{service.description}</SpacedTypography>

            <TextContainer>
              <CustomizeIncludeTitle
                data-id="customize-advantages-heading"
                margin="0.5rem 0"
              >
                {t("customize.advantages")}
              </CustomizeIncludeTitle>
              <List>
                {service.descriptionAdvantages
                  .split(";")
                  .map((advantage, index) => (
                    <ListItem key={index} primaryColor={primaryColor}>
                      <SpacedTypography>{advantage}</SpacedTypography>
                    </ListItem>
                  ))}
              </List>
            </TextContainer>
          </Service>
        );
      })}
    </Container>
  );
};

ServicesDetail.propTypes = {
  onServiceButtonClick: func.isRequired,
  selectedServices: array.isRequired,
  availableServices: array.isRequired,
};

export default ServicesDetail;
