import { string } from "prop-types";
import React, { memo } from "react";
import styled from "styled-components";

import Typography from "components/shared/Typography";
import { fontSize, lineHeight } from "components/shared/utils";

const EXPRESSION = {
  LIST_ITEM: "- ",
  NEW_LINE: "\\n",
};

const ITEM_TYPE = {
  LIST_ITEM: "LIST_ITEM",
  TEXT: "TEXT",
};

const STypography = styled(Typography)`
  font-size: ${fontSize("sm")};
  line-height: ${lineHeight("larger")};
  min-height: 1rem;
`;

const SList = styled.ul`
  color: #000;
  list-style-type: disc;
  padding: 1rem;
`;

const SListItem = styled.li`
  margin-left: 0.5rem;
`;

const PackageDetailDescription = ({ description }) => {
  let currentList = [];
  const content = [];

  const items = description
    .split(EXPRESSION.NEW_LINE)
    .map(line => line.trim())
    .map(line => {
      if (line.startsWith(EXPRESSION.LIST_ITEM)) {
        return {
          content: line.substr(EXPRESSION.LIST_ITEM.length),
          type: ITEM_TYPE.LIST_ITEM,
        };
      }

      return {
        content: line,
        type: ITEM_TYPE.TEXT,
      };
    });

  items.forEach((item, idx) => {
    if (item.type === ITEM_TYPE.LIST_ITEM) {
      currentList.push(
        <SListItem key={`${idx}-${item.content}`}>
          <STypography>{item.content}</STypography>
        </SListItem>
      );
    } else {
      if (currentList.length > 0) {
        content.push(<SList key={`${idx}-ul`}>{currentList}</SList>);
        currentList = [];
      }
      content.push(
        <STypography key={`${idx}-${item.content}`}>{item.content}</STypography>
      );
    }
  });

  return <>{content}</>;
};

PackageDetailDescription.propTypes = {
  description: string.isRequired,
};

// component is being rerendered on many sorrounding state changes (other accordions open/close, journey switch)
// memo hides /possibly expensive/ string processing and react & styled-components structure rendering from each render
// recalculation happens only on first render and then on props (description) change
export default memo(PackageDetailDescription);
