import React from "react";
import styled from "styled-components";
import { arrayOf, object } from "prop-types";

import PreconfigurationItem from "./PreconfigurationItem";
import { color, fontWeight, fontSize } from "../shared/utils";

const StyledMenu = styled.ul`
  width: 0;
  position: absolute;
  right: -20px;
  top: -20px;
  display: none;
  align-self: flex-start;
  padding-left: 18px;
  color: ${color("white")};
  font-weight: ${fontWeight("light")};
  font-size: ${fontSize("md")};
  list-style-type: none;

  & > div {
    background-color: ${color("black1")};
  }
`;

const ItemsContainer = styled.span`
  padding: 20px 30px;
  background-color: ${color("black1")};
  display: block;
`;

function SubMenu({ preconfigurationSubGroup }) {
  return (
    <StyledMenu>
      <ItemsContainer>
        {preconfigurationSubGroup.map((preconfiguration, index) => (
          <PreconfigurationItem
            singlePreconfiguration={preconfiguration}
            index={index}
            label={preconfiguration.colorCode}
            key={index}
          />
        ))}
      </ItemsContainer>
    </StyledMenu>
  );
}

SubMenu.propTypes = {
  preconfigurationSubGroup: arrayOf(object).isRequired,
};

export default SubMenu;
