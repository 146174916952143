import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "@lib/i18n";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import getDebug from "debug";
import { bool, func, number } from "prop-types";

import StickyRequestButton from "@components/shared/StickyRequestButton";
import ConfirmationPopin from "./ConfirmationPopin";
import ErrorBoundary from "../shared/ErrorBoundary";
import CustomizeItem from "./CustomizeItem";
import TechnicalDetails from "../TechnicalDetails/TechnicalDetails";
import Financing from "../Financing/Financing";
import { CollapsableContainerHeader } from "@components/shared/CollapsableContainer";

import { useAvailableServiceCategories } from "@hooks/useAvailableServiceCategories";
import { fontSize, desktop } from "../shared/utils";
import { getIsCashJourneySelected } from "@shared/v2/lib/getIsCashJourneySelected";
import { getIsLLDJourneySelected } from "@shared/v2/lib/getIsLLDJourneySelected";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { useIsUserTrustedSelector } from "@redux/reducers/auth";
import {
  useConfirmationPopinSelector,
  useIsAccessoriesDefaultOpenSelector,
  useMovesProgramSelector,
} from "@redux/reducers/appConfig";
import { useCurrentFinancingData } from "@hooks/financing/useCurrentFinancingData";
import { useIsLLDPriceBreakdownRequestPendingSelector } from "@redux/reducers/requests";
import { useGetErrorMessage } from "@hooks/useGetErrorMessage";
import { useIsInitialRender } from "@hooks/useIsInitialRender";
import { useFinanceWidgetErrorsSelector } from "@redux/reducers/errors";
import { useIsUIv2Selector } from "@redux/reducers/appConfig";
import { resetFinanceWidgetErrorCodesAction } from "@redux/actions/errorsActions";
import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { ROUTES } from "@shared/routing/routes";
import { useRoutingUtils } from "@hooks/useRoutingUtils";
import { getIsProductionMode } from "@lib/getIsProductionMode";
import { useIsJourneySwitchEnabled } from "@hooks/useIsJourneySwitchEnabled";
import gtm from "@gtm/core";
import api from "@api";
import { useIsAllowedToContinueWithOrder } from "@hooks/useIsAllowedToContinueWithOrder";
import { useStepAwareCashData } from "@hooks/financing/useStepAwareCashData";

import {
  openCustomizeSectionEvent,
  homepageAddToCartEcommerceEvent,
} from "@gtm/events/homepage";

import {
  CTA_SOL_TYPES,
  DEAL_TYPE,
  CUSTOMIZE_TYPES,
  DEAL_STATES,
} from "@shared/constants";

import {
  postDealAction,
  createNewDealFromExistingAction,
  setIsSelectionConfirmedAction,
  setLastCtaAction,
  removePromoCodeAction,
} from "@redux/actions/dealActions";

import {
  useActiveCarPreconfigurationSelector,
  useFinanceSimulationSelector,
  useCurrentDealStateSelector,
  useCarConfigurationSelector,
  useSalesmanSelector,
  useDealSelector,
} from "@redux/reducers/deal";

import {
  useCarSelector,
  useSolJourneySelector,
} from "@redux/reducers/appConfig";
import JourneySwitch from "components/PriceDetail/JourneySwitch";
import { getIsSCFJourneySelected } from "@shared/v2/lib/getIsSCFJourneySelected";

const debug = getDebug("app:components:Customize");

const { ACCESSORY, DESCRIPTION, PACK, SERVICE } = CUSTOMIZE_TYPES;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  ${desktop`
    margin-left: 1rem;
    min-width: 45%;
    max-width: 50%;

    margin-top: ${({ isDiscountStripeEnabled, isMovesProgramEnabled }) =>
      (isDiscountStripeEnabled || isMovesProgramEnabled) && "3rem"};
  `};
`;

const CustomizeItemWrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

const StyledFinancing = styled(Financing)`
  padding: ${({ isContainerOpen }) =>
    isContainerOpen ? "1rem 2rem" : "1rem 2rem 0"};

  & ${CollapsableContainerHeader} {
    padding: 0 0 1.3rem 0;
    font-size: ${fontSize("md")};
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const getIncludedObjectsOfType = (options, currentOptions, desiredType) => {
  const { PACK, PERSONALIZATION } = CUSTOMIZE_TYPES;

  const getTypeCondition = type =>
    desiredType === PACK
      ? type === PACK || type === PERSONALIZATION
      : type === desiredType;

  if (!currentOptions) {
    return [];
  }

  return options.filter(
    ({ id, type }) => currentOptions.includes(id) && getTypeCondition(type)
  );
};

const Customize = ({
  isFinancingOpen,
  onIsFinancingOpenChange,
  fixedHeaderHeight,
  isPromoCodeApplicable,
}) => {
  const primaryColor = usePrimaryColor();
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const financeSimulation = useFinanceSimulationSelector();
  const currentDealState = useCurrentDealStateSelector();
  const isLLDPriceBreakdownRequestPending = useIsLLDPriceBreakdownRequestPendingSelector();
  const selectedCarConfiguration = useCarConfigurationSelector();
  const availableServiceCategories = useAvailableServiceCategories();
  const salesman = useSalesmanSelector();
  const isUserTrusted = useIsUserTrustedSelector();
  const isB2BSelected = useIsB2BSelected();
  const isInitialRender = useIsInitialRender();
  const { discountStripe } = useSolJourneySelector();
  const financeWidgetErrors = useFinanceWidgetErrorsSelector();
  const routingUtils = useRoutingUtils();
  const isAllowedToContinue = useIsAllowedToContinueWithOrder(
    isPromoCodeApplicable
  );
  const isProduction = getIsProductionMode();
  const deal = useDealSelector();
  const isPriceSwitcherEnabled = useIsJourneySwitchEnabled();
  const showConfirmationPopin = useConfirmationPopinSelector();
  const isAccessoriesDefaultOpen = useIsAccessoriesDefaultOpenSelector();

  const movesProgram = useMovesProgramSelector();
  const isMovesProgramEnabled = movesProgram?.enabled;

  const uiv2 = useIsUIv2Selector();
  const { options } = useCarSelector();

  const [isConfirmationPopinOpen, setIsConfirmationPopinOpen] = useState(false);

  const getErrorMessage = useGetErrorMessage();

  const { options: activeCarOptions } = useActiveCarPreconfigurationSelector();
  const {
    financingLLDData,
    financingLOAData,
    financingVACData,
    financingSCFData,
  } = useCurrentFinancingData();

  const price = financeSimulation.totalPrice.cash.netPrice;
  const variant = selectedCarConfiguration.preconfiguration.label;

  const accessories = getIncludedObjectsOfType(
    options,
    activeCarOptions,
    ACCESSORY
  ).filter(
    accessory =>
      !accessory.salesmanBlacklist?.includes(salesman) &&
      accessory.status === true
  );

  const isCashJourneySelected = getIsCashJourneySelected(
    financeSimulation.journey
  );
  const isLLDJourneySelected = getIsLLDJourneySelected(
    financeSimulation.journey
  );

  const isSCFJourneySelected = getIsSCFJourneySelected(
    financeSimulation.journey
  );

  const gtmTitle = t(
    isCashJourneySelected ? "basket.myPayment.singular" : "basket.myFunding"
  );

  const packs = getIncludedObjectsOfType(
    options,
    activeCarOptions,
    PACK
  ).filter(option => option.status === true);

  const onConfirmButtonClick = () => {
    showConfirmationPopin ? setIsConfirmationPopinOpen(true) : navigateNext();
  };

  const onConfirmPopinButtonClick = async () => {
    setIsConfirmationPopinOpen(false);

    navigateNext();
  };

  const navigateNext = async () => {
    dispatch(resetFinanceWidgetErrorCodesAction());
    dispatch(removePromoCodeAction());

    // If the deal is in state order or offer, clicking the proceed button should
    // lead to creation of a new deal object - which happens later on automatically
    // if currentDeal is null.. So by resetting current deal, we should achieve this..

    gtm.fire(
      homepageAddToCartEcommerceEvent({ price, variant, isB2BSelected })
    );

    const targetLastCta = CTA_SOL_TYPES.VEHICLE_SELECTED;

    if (
      isUserTrusted &&
      [DEAL_STATES.ORDER, DEAL_STATES.OFFER].includes(currentDealState)
    ) {
      await dispatch(createNewDealFromExistingAction());
      await dispatch(postDealAction(targetLastCta, DEAL_TYPE.DEAL, null));
    }

    dispatch(setLastCtaAction(targetLastCta));
    dispatch(setIsSelectionConfirmedAction(true));

    await api.updateSession({
      lastCta: targetLastCta,
      isSelectionConfirmed: true,
    });

    // @see https://github.com/zeit/next.js/issues/3249#issuecomment-342694715
    await router.push(
      ROUTES.BASKET,
      routingUtils.getLocalizedPathname(ROUTES.BASKET, null, i18n.language)
    );

    window.scrollTo(0, 0);

    return new Promise(() => null);
  };

  return (
    <Container
      data-id="customize-container"
      isDiscountStripeEnabled={discountStripe.enabled}
      isMovesProgramEnabled={isMovesProgramEnabled}
    >
      {uiv2 && isPriceSwitcherEnabled && (
        <ErrorBoundary>
          <Center>
            <JourneySwitch isTextVersion isUIv2={uiv2} />
          </Center>
        </ErrorBoundary>
      )}
      <ErrorBoundary>
        <CustomizeItemWrapper>
          <CustomizeItem
            type={PACK}
            packages={packs}
            singleItem={packs.length === 1}
            title={DESCRIPTION}
            readOnly
          />
        </CustomizeItemWrapper>
      </ErrorBoundary>
      <AnimatePresence>
        {isCashJourneySelected && availableServiceCategories?.length && (
          <CustomizeItemWrapper>
            <motion.div
              initial={isInitialRender ? "open" : "collapsed"}
              animate="open"
              exit="collapsed"
              variants={{
                open: {
                  opacity: 1,
                  height: "auto",
                  display: "block",
                },
                collapsed: {
                  opacity: 0,
                  height: 0,
                  transitionEnd: { display: "none" },
                  transition: { duration: 0.3, type: "tween" },
                },
              }}
            >
              <ErrorBoundary>
                <CustomizeItem
                  type={SERVICE}
                  packages={availableServiceCategories.map(service => ({
                    ...service,
                    type: CUSTOMIZE_TYPES.SERVICE,
                  }))}
                  singleItem={availableServiceCategories.length === 1}
                />
              </ErrorBoundary>
            </motion.div>
          </CustomizeItemWrapper>
        )}
      </AnimatePresence>
      <ErrorBoundary>
        {accessories.length > 0 && (
          <CustomizeItemWrapper>
            <CustomizeItem
              type={ACCESSORY}
              packages={accessories}
              singleItem={accessories.length === 1}
              defaultOpen={isAccessoriesDefaultOpen}
            />
          </CustomizeItemWrapper>
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        <CustomizeItemWrapper>
          <StyledFinancing
            deal={deal}
            journey={financeSimulation.journey}
            index={null}
            withDeliveryInfo
            cashData={useStepAwareCashData(true)}
            lldData={financingLLDData}
            loaData={financingLOAData}
            vacData={financingVACData}
            scfData={financingSCFData}
            isLoading={
              isLLDJourneySelected && isLLDPriceBreakdownRequestPending
            }
            isCurrentStep={true}
            onChange={isOpen => {
              onIsFinancingOpenChange(!isOpen);

              // isOpen might be also undefined, so we need strict check
              if (isOpen === false) {
                gtm.fire(openCustomizeSectionEvent(gtmTitle));
              }
            }}
            errorMessages={financeWidgetErrors.map(getErrorMessage)}
            isContainerOpen={isFinancingOpen}
            opened={isFinancingOpen}
            isB2BSelected={isB2BSelected}
            isReadOnly={isSCFJourneySelected}
          />
        </CustomizeItemWrapper>
      </ErrorBoundary>
      <ErrorBoundary>
        <CustomizeItemWrapper>
          <TechnicalDetails />
        </CustomizeItemWrapper>
      </ErrorBoundary>
      <StickyRequestButton
        data-id="customize-order-button"
        primaryColor={primaryColor}
        triggerRequestCallback={onConfirmButtonClick}
        disabled={!isAllowedToContinue}
        fixedHeaderHeight={fixedHeaderHeight}
        isStickyToTop={true}
        isStickyToBottom={true}
        onRequestError={error => {
          if (isProduction) {
            return;
          }

          debug(error);
        }}
      >
        {t("customize.order")}
      </StickyRequestButton>
      <ConfirmationPopin
        isOpen={isConfirmationPopinOpen}
        onConfirm={onConfirmPopinButtonClick}
        onClose={() => setIsConfirmationPopinOpen(false)}
      />
    </Container>
  );
};

Customize.propTypes = {
  isFinancingOpen: bool.isRequired,
  onIsFinancingOpenChange: func.isRequired,
  fixedHeaderHeight: number,
  isPromoCodeApplicable: bool,
};

export default Customize;
