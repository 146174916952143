import { produce, setAutoFreeze } from "immer";

import { useCarSelector } from "@redux/reducers/appConfig";
import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { BUSINESS_MODELS_TARGET_KEYS } from "@shared/constants";

setAutoFreeze(false);

export const useAvailableServiceCategories = () => {
  const { services } = useCarSelector();
  const { lcdvCode } = useActiveCarPreconfigurationSelector();

  const isB2BSelected = useIsB2BSelected();

  const targetServices = services.filter(service => {
    const targetKey = isB2BSelected
      ? BUSINESS_MODELS_TARGET_KEYS.B2B
      : BUSINESS_MODELS_TARGET_KEYS.B2C;

    return service[targetKey] === true && service.lcdvCode === lcdvCode;
  });

  return targetServices
    .concat()
    .sort((a, b) => (a.sortWeight > b.sortWeight ? 1 : -1))
    .reduce((acc, cur) => {
      if (acc.length === 0) {
        return [{ id: cur.id, label: cur.groupLabel, services: [cur] }];
      }

      const lastGroup = acc[acc.length - 1];
      const lastItem = lastGroup.services[lastGroup.services.length - 1];

      if (lastItem.groupLabel === cur.groupLabel) {
        return produce(acc, draft => {
          draft[acc.length - 1].services.push(cur);
        });
      }

      return produce(acc, draft => {
        draft.push({ id: cur.id, label: cur.groupLabel, services: [cur] });
      });
    }, []);
};
