import React, { forwardRef, useRef } from "react";
import styled from "styled-components";

import RequestButton from "@components/shared/RequestButton";

import {
  fontSize,
  fontWeight,
  onlyMobiles,
  onlyTabletAndDesktop,
} from "@components/shared/utils";
import { string, bool, number } from "prop-types";
import { useIsStickyTopOrBottom } from "@hooks/useIsStickyTopOrBottom";

const Positioner = styled("div")`
  ${onlyTabletAndDesktop`
    display: none;
  `}
`;

const StyledRequestButton = styled(RequestButton)`
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("md")};
  margin: 1rem auto;
  justify-content: center;
  align-items: center;

  ${onlyMobiles`
    ${({
      stickyStyles,
      stickToTop,
      stickToBottom,
      fixedHeaderHeight,
      fixedFooterHeight,
    }) =>
      (stickToTop || stickToBottom) &&
      `
        position: fixed;
        top: ${stickToTop ? fixedHeaderHeight.toString() + "px" : "unset"};
        bottom: ${
          stickToBottom ? fixedFooterHeight.toString() + "px" : "unset"
        };
        width: 100%;
        margin: 0;
        border-radius: 0;
        border: 4px solid #333333;
        z-index: 999;
        ${stickyStyles}
    `}
  `}
`;

const StickyRequestButton = forwardRef(
  (
    {
      stickyStyles = "",
      isStickyToTop = false,
      isStickyToBottom = false,
      fixedHeaderHeight = 0,
      fixedFooterHeight = 0,
      ...props
    },
    ref
  ) => {
    const positionerRef = useRef(null);

    const { stickToTop, stickToBottom } = useIsStickyTopOrBottom(
      isStickyToTop,
      isStickyToBottom,
      positionerRef,
      fixedHeaderHeight
    );

    return (
      <>
        <Positioner ref={positionerRef} />
        <StyledRequestButton
          ref={ref}
          stickyStyles={stickyStyles}
          stickToTop={isStickyToTop && stickToTop}
          stickToBottom={isStickyToBottom && stickToBottom}
          fixedHeaderHeight={fixedHeaderHeight}
          fixedFooterHeight={fixedFooterHeight}
          {...props}
        />
      </>
    );
  }
);

StickyRequestButton.displayName = "StickyRequestButton";

StickyRequestButton.propTypes = {
  stickyStyles: string,
  isStickyToTop: bool,
  isStickyToBottom: bool,
  fixedHeaderHeight: number,
  fixedFooterHeight: number,
};

export default StickyRequestButton;
