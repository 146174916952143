import React from "react";

import BaseIcon from "../BaseIcon";

const DownloadIcon = props => (
  <BaseIcon viewBox="0 0 32 32" {...props}>
    <path
      stroke="currentColor"
      d="M21.39,24.61a1,1,0,0,1,0,1.41l-4.68,4.69a1,1,0,0,1-.33.21.94.94,0,0,1-.76,0,1,1,0,0,1-.33-.21L10.61,26A1,1,0,1,1,12,24.61l3,3V11.31a1,1,0,0,1,2,0V27.59l3-3A1,1,0,0,1,21.39,24.61ZM26.79,1H5.21A4.22,4.22,0,0,0,1,5.21v13A4.22,4.22,0,0,0,5.21,22.4h6.1a1,1,0,1,0,0-2H5.21A2.21,2.21,0,0,1,3,18.19v-13A2.21,2.21,0,0,1,5.21,3H26.79A2.21,2.21,0,0,1,29,5.21v13a2.21,2.21,0,0,1-2.21,2.21H20.53a1,1,0,0,0,0,2h6.26A4.22,4.22,0,0,0,31,18.19v-13A4.22,4.22,0,0,0,26.79,1Z"
    />
  </BaseIcon>
);

export default DownloadIcon;
