import { CTA_SOL_TYPES } from "@shared/constants";
import {
  useFinanceSimulationSelector,
  useLastCtaSelector,
} from "@redux/reducers/deal";
import { useCurrentFinancingData } from "@hooks/financing/useCurrentFinancingData";

export const useStepAwareCashData = (forceTotal = false) => {
  const { financingCashData } = useCurrentFinancingData();
  const lastCta = useLastCtaSelector();
  const { totalPrice, vehiclePrice } = useFinanceSimulationSelector();

  return {
    ...financingCashData,

    totalPrice:
      lastCta === CTA_SOL_TYPES.VEHICLE_SELECTED && !forceTotal
        ? vehiclePrice.cash.netPrice
        : totalPrice.cash.netPrice,

    totalPriceWithoutTax:
      lastCta === CTA_SOL_TYPES.VEHICLE_SELECTED && !forceTotal
        ? vehiclePrice.cash.basePrice
        : totalPrice.cash.basePrice,
  };
};
