import { useFinanceAccessoriesIncreaseSelector } from "@redux/reducers/deal";

export const useAccessoryIncrease = accessoryId => {
  const accessoriesIncrease = useFinanceAccessoriesIncreaseSelector();

  if (!accessoriesIncrease) {
    return null;
  }

  const matchingAccessory = accessoriesIncrease.find(
    ({ key }) => key === accessoryId
  );

  return matchingAccessory?.increase.amountInclTax ?? null;
};
