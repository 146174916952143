import React from "react";
import { number } from "prop-types";

import LoadingDots from "@components/shared/LoadingDots";

import { useIsLLDPriceBreakdownRequestPendingSelector } from "@redux/reducers/requests";
import { getFormattedPrice } from "@shared/v2/lib/getFormattedPrice";
import { useCurrencySettingsSelector } from "@redux/reducers/appConfig";

const FinancePrice = ({ amount }) => {
  const isLLDPriceBreakdownRequestPending = useIsLLDPriceBreakdownRequestPendingSelector();
  const currencySettings = useCurrencySettingsSelector();

  if (isLLDPriceBreakdownRequestPending || !amount) {
    return <LoadingDots />;
  }

  return (
    <span>
      {getFormattedPrice(
        amount,
        currencySettings.format,
        currencySettings.symbol
      )}
    </span>
  );
};

FinancePrice.propTypes = {
  amount: number,
};

export default FinancePrice;
