import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { string, number } from "prop-types";

import ReplayIcon from "@components/shared/Icons/ReplayIcon";
import YouTubeIcon from "@components/shared/Icons/YouTubeIcon";

import { desktop, tablet } from "@components/shared/utils";

const YouTubeWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 5%;
  top: 0;
  max-width: 40rem;

  ${desktop`
    padding: 0;
    left:0;
  `}
`;

const YouTubeContainer = styled.div`
  ${tablet`
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  `}
`;

const YouTubeOverlayContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  background-color: #000;
  align-items: center;
  justify-content: center;
`;

const YouTubeVideo = styled.div`
  width: 90%;

  ${tablet`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  `}
`;

const YouTube = ({ videoId, height, width }) => {
  const [isYouTubeVideoPause, setIsYouTubeVideoPause] = useState(false);
  const [isYouTubeVideoEnd, setIsYouTubeVideoEnd] = useState(false);

  const playerRef = useRef(null);
  const timeoutRef = useRef(null);

  const onPlayerStateChange = event => {
    clearTimeout(timeoutRef.current);

    if (event.data === window.YT.PlayerState.ENDED) {
      setIsYouTubeVideoEnd(true);
      setIsYouTubeVideoPause(false);

      return;
    }

    if (event.data === window.YT.PlayerState.PLAYING) {
      timeoutRef.current = setTimeout(() => {
        setIsYouTubeVideoEnd(false);
        setIsYouTubeVideoPause(false);
      }, 250);

      return;
    }

    if (event.data === window.YT.PlayerState.PAUSED) {
      timeoutRef.current = setTimeout(() => {
        setIsYouTubeVideoEnd(false);
        setIsYouTubeVideoPause(true);
      }, 150);
    }
  };

  const playVideo = () => {
    playerRef.current.playVideo();
  };

  useEffect(() => {
    if (!window.YT) {
      const script = document.createElement("script");

      script.src = "https://www.youtube.com/iframe_api";

      document.head.appendChild(script);
    }

    const createVideoPlayer = () => {
      playerRef.current = new window.YT.Player("player", {
        frameBorder: "0",

        allow:
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",

        events: {
          onReady: event => event.target.playVideo(),
          onStateChange: onPlayerStateChange,
        },

        height,
        width,
        videoId,
      });
    };

    window.onYouTubeIframeAPIReady = createVideoPlayer;

    if (window.YT) {
      createVideoPlayer();
    }
  }, []);

  return (
    <YouTubeWrapper>
      <YouTubeContainer>
        <YouTubeVideo id="player" />

        {(isYouTubeVideoPause || isYouTubeVideoEnd) && (
          <YouTubeOverlayContainer onClick={playVideo}>
            {isYouTubeVideoEnd && <ReplayIcon />}
            {isYouTubeVideoPause && <YouTubeIcon />}
          </YouTubeOverlayContainer>
        )}
      </YouTubeContainer>
    </YouTubeWrapper>
  );
};

YouTube.propTypes = {
  videoId: string.isRequired,
  height: number.isRequired,
  width: number.isRequired,
};

export default YouTube;
