import React from "react";
import styled, { useTheme } from "styled-components";
import { func, bool, string } from "prop-types";
import ArrowDownIcon from "../../shared/Icons/ArrowDown";

import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { withTabNavigation } from "@hoc/withTabNavigation";

import Typography from "../../shared/Typography";
import { font, fontWeight } from "../../shared/utils";

const Container = withTabNavigation(
  styled.div`
    background: ${({ backgroundUrl }) => backgroundUrl};
    background-size: cover;
    border: ${({ isSelected, primaryColor }) =>
      isSelected ? `2px solid ${primaryColor}` : "none"};
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    flex: 0 0 auto;
    height: 120px;
    padding: 0.75rem;
    text-transform: uppercase;
    white-space: initial;
    width: ${({ singleItem }) => (singleItem ? "100% " : "120px")};
  `,
  "button",
  true
);

const Button = styled.div`
  align-items: center;
  background: ${({ isSelected, theme, primaryColor }) =>
    isSelected ? primaryColor : theme.colors.white};
  border-top-left-radius: 5px;
  bottom: 0;
  color: ${({ isSelected, primaryColor, theme }) =>
    isSelected ? theme.colors.white : primaryColor};
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 0;
  transition: 0.1s linear color;
  width: 40px;

  &:hover {
    color: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.white : theme.colors.black1};
  }
`;

const StyledCitroenTypography = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
  text-shadow: #000 0 0 1px;
`;

const ClosedPackage = ({
  onClick,
  title,
  isSelected,
  backgroundUrl,
  singleItem,
}) => {
  const primaryColor = usePrimaryColor();
  const theme = useTheme();

  return (
    <Container
      data-id={`closed-package-${title}`}
      className="closedPackage"
      onClick={onClick}
      backgroundUrl={backgroundUrl}
      isSelected={isSelected}
      singleItem={singleItem}
      primaryColor={primaryColor}
    >
      <StyledCitroenTypography
        size="sm"
        customColor={theme.colors.white}
        data-id={`closed-package-${title}`}
      >
        {title}
      </StyledCitroenTypography>
      <Button
        data-id={`closed-package-${title}-button`}
        isSelected={isSelected}
        primaryColor={primaryColor}
      >
        <ArrowDownIcon width="16px" height="16px" />
      </Button>
    </Container>
  );
};

ClosedPackage.propTypes = {
  onClick: func.isRequired,
  title: string.isRequired,
  isSelected: bool.isRequired,
  backgroundUrl: string.isRequired,
  singleItem: bool,
};

export default ClosedPackage;
