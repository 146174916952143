import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { bool, func, any } from "prop-types";
import Typography from "components/shared/Typography";
import Heading from "components/shared/Heading";
import CircularLoader from "@components/shared/Icons/CircularLoader";
import {
  color,
  fontWeight,
  fontSize,
  smallMobile,
  tablet,
} from "@components/shared/utils";
import { useTranslation } from "@lib/i18n";
import { useFormatText } from "@hooks/useFormatText";

import { useVACPriceBreakdownSelector } from "@redux/reducers/deal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  padding: 1rem;
  bottom: 0;
  z-index: 9999;
  background-color: ${color("white")};
  width: 100vw;
  border: 5px solid ${color("darkNavy")};
  left: 0;
  overflow-y: scroll;

  ${smallMobile`
    max-height: 25vh;
  `}
  ${tablet`
    max-height:50vh;
  `}
`;

const ButtonText = styled(Typography)`
  font-weight: ${fontWeight("bolder")};
  letter-spacing: 0.6px;
  font-size: 0.8rem;
  color: ${color("white")};
  text-align: center;
  cursor: default;
  text-transform: uppercase;
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

const StyledButton = styled.button`
  align-items: center;
  background: ${color("brightOrange")};
  border: none;
  outline: none;
  display: flex;
  padding: 1rem 2rem;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline-offset: 2px !important;
  }
`;

const StyledHeading = styled(Heading)`
  font-size: ${fontSize("lg")};
  font-weight: ${fontWeight("regular")};
  text-align: center;
  margin-bottom: 1rem;

  ${tablet`
    font-size: ${fontSize("xl")};
  `}
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 1rem;
`;

const CircularLoaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledCircularLoader = styled(CircularLoader)`
  color: ${color("black1")};
  height: 3rem;
  width: 3rem;
`;

const LegalRequirementPopIn = ({ onClose, isLoading }) => {
  const vacPriceBreakdown = useVACPriceBreakdownSelector();
  const { t } = useTranslation();

  const [legalTextFields, setLegalTextFields] = useState({
    slogan: "",
    mainText: "",
    primaryText: "",
  });

  const setTextFields = priceObject => {
    const {
      disclaimer: { representativeExample, slogan },
      teaser: { primarytext },
    } = priceObject?.vehicleSimulation.result;

    setLegalTextFields({
      ...legalTextFields,
      slogan: slogan.text,
      mainText: representativeExample[0].text,
      primaryText: primarytext[0].text,
    });
  };

  const makeLegalText = text => useFormatText(text).join(" ");

  useEffect(() => {
    if (vacPriceBreakdown) {
      setTextFields(vacPriceBreakdown);
    }
  }, [vacPriceBreakdown]);

  return (
    <Container onClose={onClose}>
      {isLoading ? (
        <CircularLoaderContainer>
          <StyledCircularLoader />
        </CircularLoaderContainer>
      ) : (
        <>
          <StyledHeading
            dangerouslySetInnerHTML={{
              __html: makeLegalText(legalTextFields.slogan),
            }}
          />
          <StyledTypography>{ legalTextFields.mainText }</StyledTypography>
          <StyledButton onClick={onClose} data-id="seller-contact-close-icon">
            <ButtonText>{t("basket.financing.ctaButton")}</ButtonText>
          </StyledButton>
        </>
      )}
    </Container>
  );
};

LegalRequirementPopIn.propTypes = {
  isLoading: any.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default LegalRequirementPopIn;
