import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "@lib/i18n";

import Carousel from "./Carousel";
import YouTube from "./YouTube";
import ThreeSixty from "../3DModel/ThreeSixty";
import Icon360 from "../shared/Icons/360Icon";
import YouTubeIcon from "../shared/Icons/YouTubeIcon";

import Typography from "../shared/Typography";
import CloseIcon from "../shared/Icons/Close";
import ErrorBoundary from "../shared/ErrorBoundary";
import gtm from "@gtm/core";

import { usePreconfigurationsSelector } from "@redux/reducers/appConfig";
import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";
import { getCarImagePath } from "@shared/v2/lib/getCarImagePath";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { setLastChangedAtAction } from "@redux/actions/deprecatedActions";

import { usePrimaryColor } from "@hooks/usePrimaryColor";

import { updateCarPreconfigurationAction } from "@redux/actions/dealActions";

import { openThreeSixtyEvent } from "@gtm/events/homepage";

import {
  desktop,
  color,
  fontSize,
  tabletLarge,
  font,
  fontWeight,
  tablet,
  smallMobile,
} from "../shared/utils";

import { CAR_GALLERY_360_ICON } from "@shared/constants";

const Container = styled.div`
  position: relative;
  max-width: 40rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  width: 100%;

  ${desktop`
    margin-bottom: 0;
  `};
`;

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 88vw;
  padding: 1rem;
  background: ${color("white")};
  border-radius: 0.7rem;
  margin-right: 3rem;
  max-width: 35rem;
  min-height: 22rem;
  padding-bottom: 0.25rem;

  ${desktop`
    height: 100%;
    width: 100%;
    padding: 2rem;
    margin: 0 auto;
  `}
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 45%;
  border-bottom: ${({ theme, id, youTubeVideoId }) =>
    (CAR_GALLERY_360_ICON.includes(id) || youTubeVideoId) &&
    `2px solid ${theme.colors.grey}`};

  position: relative;
  margin-bottom: 1.5rem;

  padding: ${({ withThreeSixtyInfo }) => !withThreeSixtyInfo && "0.5rem"};
  box-sizing: ${({ withThreeSixtyInfo }) =>
    !withThreeSixtyInfo && "content-box"};

  ${desktop`
    padding: 0;
    box-sizing: border-box;
  `};

  ${desktop`
    height: 21.5rem;
    padding: 1rem;
    width: 80%;
    margin-bottom: 2rem;
  `};
`;

const Image = styled.img`
  width: 90%;
  margin-top: -3rem;

  ${desktop`
    width: 110%;
    height: auto;
    margin-top: -3rem;
  `}

  ${desktop`
    margin-top: -4.5rem;
    width: 130%;
  `}
`;

const ModelWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 5%;
  top: 0;
  visibility: ${({ isOpen }) => !isOpen && "hidden"};
  max-width: 40rem;

  ${desktop`
    padding: 0;
    left:0;
  `}
`;

const Button = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2rem solid ${color("grey")};
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 0.2rem;
  background-color: ${color("white")};
  position: absolute;
  bottom: -1.5rem;
  box-shadow: 0px 0px 0px 0px ${color("grey")};
  transition: 0.1s ease-in-out box-shadow;

  &:hover {
    box-shadow: 0px 0px 7px 0px ${color("grey")};
  }
`;

const CloseThreeSixtyWrapper = styled.div`
  position: absolute;
  top: 2%;
  right: 10%;
  border: 0.2rem solid ${color("grey")};
  background: rgba(255, 255, 255, 0.5);
  padding: 0.6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 0.2rem;
  z-index: 1;
  box-shadow: 0px 0px 0px 0px ${color("grey")};
  transition: 0.1s ease-in-out box-shadow;

  ${tablet`
    right: 4%;
  `}

  ${desktop`
    right: 2%;
  `}

  &:hover {
    box-shadow: 0px 0px 7px 0px ${color("grey")};
  }
`;

const StyledTypography = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bolder")};
  font-size: ${fontSize("sm")};
  padding: 0.3rem 0;

  ${smallMobile`
    padding: 1rem 0;
  `}

  ${desktop`
    font-size: ${fontSize("md")};
  `}
`;

const ThreeSixtyInfo = styled(Typography)`
  font-size: ${fontSize("xs")};
  color: ${color("grey1")};
  line-height: 1.5;
  padding: 1rem 0;

  ${desktop`
    padding-bottom: 0rem;
  `}
`;

const CloseYouTubeVideoWrapper = styled.div`
  position: absolute;
  top: 6%;
  right: 30%;
  border: 0.2rem solid ${color("grey")};
  padding: 0.6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 0.2rem;
  z-index: 1;
  box-shadow: 0px 0px 0px 0px ${color("grey")};
  transition: 0.1s ease-in-out box-shadow;
  background-color: white;

  ${tablet`
    right: 25%;
    top: 4%;
  `}

  ${tabletLarge`
    right: 25%;
    top: 6%;
  `}

  ${desktop`
    right: 45%;
    top: 3%;
  `}

  &:hover {
    box-shadow: 0px 0px 7px 0px ${color("grey")};
  }
`;

const CarGallery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const preconfigurations = usePreconfigurationsSelector();

  const activePreconfiguration = useActiveCarPreconfigurationSelector();
  const primaryColor = usePrimaryColor();
  const [threeSixtyOpen, setThreeSixtyOpen] = useState(false);
  const [youTubeVideoOpen, setYouTubeVideoOpen] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(
    preconfigurations.findIndex(
      config => config.id === activePreconfiguration.id
    )
  );

  const toggleThreeSixtyOpen = () => {
    gtm.fire(
      openThreeSixtyEvent({
        label: t("carGallery.360"),
        goingToOpen: !threeSixtyOpen,
      })
    );
    setThreeSixtyOpen(!threeSixtyOpen);
  };

  const toggleYouTubeVideoOpen = () => {
    setYouTubeVideoOpen(!youTubeVideoOpen);
  };

  const handleConfigurationDispatch = index => {
    dispatch(updateCarPreconfigurationAction(preconfigurations[index]));
    dispatch(setLastChangedAtAction(Date.now()));
  };

  const handleSlideChange = index => {
    if (currentIndex === index) {
      return;
    }

    setCurrentIndex(index);

    handleConfigurationDispatch(index);
  };

  useEffect(() => {
    setCurrentIndex(
      preconfigurations.findIndex(
        config => config.id === activePreconfiguration.id
      )
    );
  }, [activePreconfiguration.id, preconfigurations]);

  useEffect(() => {
    setYouTubeVideoOpen(false);
    setThreeSixtyOpen(false);
  }, [activePreconfiguration.id]);

  return (
    <Container>
      <>
        {threeSixtyOpen && (
          <ModelWrapper isOpen={threeSixtyOpen}>
            <ErrorBoundary>
              <ThreeSixty />
            </ErrorBoundary>
            <CloseThreeSixtyWrapper
              aria-label={t("basket.back")}
              onClick={toggleThreeSixtyOpen}
            >
              <CloseIcon
                data-id="360-close-icon"
                color="#000"
                height="9px"
                width="9px"
              />
            </CloseThreeSixtyWrapper>
          </ModelWrapper>
        )}
        {youTubeVideoOpen && (
          <div>
            <YouTube
              videoId={activePreconfiguration.youTubeVideoId}
              width={560}
              height={315}
            />
            <CloseYouTubeVideoWrapper
              aria-label={t("basket.back")}
              onClick={toggleYouTubeVideoOpen}
            >
              <CloseIcon
                data-id="360-close-icon"
                color={primaryColor}
                width="9px"
                height="9px"
              />
            </CloseYouTubeVideoWrapper>
          </div>
        )}
      </>
      <>
        <Carousel
          afterSlide={handleSlideChange}
          currentIndex={currentIndex}
          isHidden={threeSixtyOpen || youTubeVideoOpen}
        >
          {preconfigurations.map(
            ({ label, id, additionalText, youTubeVideoId }) => (
              <SliderWrapper key={id} tabIndex={-1} role="button">
                <ImageWrapper
                  withThreeSixtyInfo={additionalText}
                  id={id}
                  youTubeVideoId={youTubeVideoId || 0}
                >
                  <Image
                    data-id={`car-model-360-offview-${id}`}
                    src={getStaticAssetURL(getCarImagePath(id))}
                    alt={label}
                    title={label}
                    onError={event =>
                      event.target.setAttribute(
                        "src",
                        getStaticAssetURL(
                          "/images/preconfigs/front-default.png"
                        )
                      )
                    }
                  />
                  {youTubeVideoId ? (
                    <Button
                      data-id={`youtube-button-${id}`}
                      onClick={() => toggleYouTubeVideoOpen()}
                    >
                      <YouTubeIcon
                        data-id={`youtube-button-${id}`}
                        color={primaryColor}
                        size="sm"
                      ></YouTubeIcon>
                    </Button>
                  ) : (
                    CAR_GALLERY_360_ICON.includes(id) && (
                      <Button
                        data-id={`360-in-button-${id}`}
                        onClick={toggleThreeSixtyOpen}
                      >
                        <Icon360
                          data-id={`360-icon-in-button-${id}`}
                          color={primaryColor}
                          size="sm"
                        />
                      </Button>
                    )
                  )}
                </ImageWrapper>

                <ThreeSixtyInfo align="center">
                  {activePreconfiguration.youTubeVideoId
                    ? t("carGallery.youtube")
                    : CAR_GALLERY_360_ICON.includes(id) && t("carGallery.360")}
                </ThreeSixtyInfo>

                <StyledTypography uppercase align="center">
                  {additionalText}
                </StyledTypography>
              </SliderWrapper>
            )
          )}
        </Carousel>
      </>
    </Container>
  );
};

export default React.memo(CarGallery);
