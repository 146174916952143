import React from "react";

import BaseIcon from "../BaseIcon";

const ArrowUpIcon = props => (
  <BaseIcon viewBox="0 0 30.728 30.729" {...props}>
    <path d="M29.996,24.08c-0.977,0.978-2.561,0.978-3.535,0L15.365,12.985L4.268,24.081C3.78,24.568,3.14,24.812,2.5,24.812s-1.28-0.244-1.768-0.731c-0.977-0.978-0.977-2.56,0-3.535L15.364,5.915l14.63,14.629C30.972,21.521,30.972,23.104,29.996,24.08z" />
  </BaseIcon>
);

export default ArrowUpIcon;
