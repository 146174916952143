import React from "react";
import { useTranslation } from "@lib/i18n";
import styled from "styled-components";
import { motion } from "framer-motion";

import ExpansionPanel from "@components/shared/ExpansionPanel";
import Typography from "@components/shared/Typography";
import PlusCircleIcon from "@components/shared/Icons/PlusCircleIcon";
import MinusIcon from "@components/shared/Icons/MinusIcon";
import DownloadIcon from "@components/shared/Icons/Download";
import Button from "@components/shared/Button";

import gtm from "@gtm/core";
import { getStaticAssetURL } from "@lib/getStaticAssetURL";
import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { openDocument } from "@lib/openDocument";
import { useGetAssetPath } from "hooks/useGetAssetPath";
import { PDF_DOCUMENT_TYPES } from "@shared/constants";
import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";
import { withTabNavigation } from "@hoc/withTabNavigation";

import {
  color,
  font,
  fontSize,
  fontWeight,
  prop,
  desktop,
  lineHeight,
} from "../shared/utils";

import {
  displayTechnicalDetailsEvent,
  openCustomizeSectionEvent,
} from "@gtm/events/homepage";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  margin-bottom: 2rem;
`;

const ItemContainer = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    height: 5rem;
    margin-bottom: 1rem;

    ${desktop`
      height: 6.5rem;
    `}
  }
`;

const UnderLine = styled.div`
  width: ${({ horizontal }) => (horizontal ? "100%" : "2px")};
  height: ${({ horizontal }) => (!horizontal ? "100%" : "2px")};
  background: ${color("grey")};
  opacity: 0.5;
  ${({ horizontal }) =>
    horizontal ? "margin-bottom: 0.4rem" : "margin-left: 0.2rem"};
`;

const HelperContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 8rem;
  margin: 0 0.5rem;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.3rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  color: ${color("black")};
  margin: 0.5rem 0;
`;

const HeaderContainer = withTabNavigation(styled.div`
  align-items: center;
  background-color: ${({ theme, currentThemeColor, isOpen }) =>
    isOpen ? theme.colors.white : currentThemeColor};
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 2rem;
  width: 100%;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  transition: background-color 0.2s ease-in-out
      ${({ isOpen }) => (isOpen ? "0s " : "0.5s")},
    color 0.2s linear;
  color: ${({ theme, primaryColor, isOpen }) =>
    isOpen ? primaryColor : theme.colors.white};

  &:hover {
    color: ${({ isOpen, primaryColor, theme }) =>
      isOpen ? primaryColor : theme.colors.black};
  }
`);

const HeaderTypography = styled(Typography)`
  font-weight: ${fontWeight("bold")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("md")};
  text-transform: lowercase;
  user-select: none;
`;

const Content = styled(motion.div)`
  background-color: ${color("white")};
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  overflow-y: hidden;
  transition: background-color 0.3s ease-in-out;
`;

const AnimationHelper = styled(motion.div)`
  padding: 2rem 2rem 1rem;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  margin: 1rem auto;
`;

const StyledPlusCircleIcon = styled(PlusCircleIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  font-size: 2rem;
  stroke: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.white : "currentColor"};
  background-color: ${prop("primaryColor")};
`;

const StyledMinusIcon = styled(MinusIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  font-size: 2rem;
  stroke: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.white : "currentColor"};
  background-color: ${prop("primaryColor")};
`;

const Image = styled.img`
  width: 100%;
`;

const ParameterName = styled(Typography)`
  font-weight: ${fontWeight("bold")};
  font-family: ${font("citroen")};
  text-transform: uppercase;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  margin-right: 0.5rem;
`;

const SpacedTypography = styled(Typography)`
  line-height: ${lineHeight("larger")};
`;

const TechnicalDetails = () => {
  const { t, i18n } = useTranslation();
  const getAssetPath = useGetAssetPath(i18n.language);

  const primaryColor = usePrimaryColor();
  const activePreconfiguration = useActiveCarPreconfigurationSelector();

  const openTechnicalDetails = () => {
    gtm.fire(displayTechnicalDetailsEvent());
    openDocument(getAssetPath(PDF_DOCUMENT_TYPES.TECHNICAL_CHARACTERISTICS));
  };

  const gtmEvent = (event, isOpen) => {
    if (!isOpen) {
      gtm.fire(openCustomizeSectionEvent(t("technicalDetails.title")));
    }
  };

  return (
    <ExpansionPanel
      dataId="technical-details-open"
      gtmEvent={gtmEvent}
      icon={({ isOpen }) =>
        isOpen ? (
          <StyledMinusIcon
            primaryColor={primaryColor}
            isOpen={isOpen}
            viewBox="0 0 100 100"
            data-id={`technical-details-icon-${isOpen ? "opened" : "closed"}`}
          />
        ) : (
          <StyledPlusCircleIcon
            primaryColor={primaryColor}
            isOpen={isOpen}
            viewBox="0 0 100 100"
            data-id={`technical-details-icon-${isOpen ? "opened" : "closed"}`}
          />
        )
      }
      header={({ isOpen, icon }) => (
        <HeaderContainer isOpen={isOpen} primaryColor={primaryColor}>
          <HeaderTypography
            forwardedAs={"h2"}
            isOpen={isOpen}
            customColor="currentColor"
          >
            {t("technicalDetails.title")}
          </HeaderTypography>
          {icon}
        </HeaderContainer>
      )}
      content={() => (
        <Content
          data-id="technical-details-container"
          initial="initial"
          animate="open"
          exit="exit"
          variants={{
            initial: {
              height: 0,
              display: "none",
            },
            open: {
              opacity: 1,
              height: "auto",
              display: "block",
              transition: { duration: 0.3, type: "tween" },
            },
            closed: {
              opacity: 0,
              height: 0,
              transitionEnd: { display: "none" },
              transition: { duration: 0.3, type: "tween" },
            },
            exit: {
              height: 0,
              transitionEnd: { display: "none" },
              transition: { duration: 0.3, type: "tween" },
            },
          }}
        >
          <AnimationHelper
            variants={{
              initial: {
                opacity: 0,
                display: "none",
              },
              open: {
                opacity: 1,
                display: "block",
                transition: { duration: 0.6 },
              },
              exit: {
                opacity: 0,
                transitionEnd: { display: "none" },
                transition: { duration: 0.5 },
              },
            }}
          >
            {t("technicalDetails.importantInformation") && (
              <StyledButton
                style={{ marginTop: 0, marginBottom: "3rem" }}
                withBorder
                primaryColor={primaryColor}
                onClick={() =>
                  openDocument(
                    getAssetPath(PDF_DOCUMENT_TYPES.IMPORTANT_INFORMATION)
                  )
                }
              >
                <StyledDownloadIcon
                  primaryColor={primaryColor}
                  viewBox="0 0 32 32"
                  data-id="technical-details-icon-download"
                />
                {t("technicalDetails.importantInformation")}
              </StyledButton>
            )}
            <Container data-id="technical-details-images">
              <ItemContainer>
                <img
                  src={getStaticAssetURL(
                    "/images/gallery/technical-characteristics/model_front.jpg"
                  )}
                  alt={t("technicalDetails.front")}
                  title={t("technicalDetails.front")}
                />
                <UnderLine horizontal />
                <ParameterName>{t("technicalDetails.width")}</ParameterName>
                <Typography>
                  {activePreconfiguration.width}
                  {t("general.meterSymbol")}
                </Typography>
              </ItemContainer>
              <ItemContainer>
                <HelperContainer>
                  <UnderLine />
                  <TextContainer>
                    <ParameterName>
                      {t("technicalDetails.height")}
                    </ParameterName>
                    <Typography>
                      {activePreconfiguration.height}
                      {t("general.meterSymbol")}
                    </Typography>
                  </TextContainer>
                </HelperContainer>
              </ItemContainer>
              <ItemContainer>
                <img
                  src={getStaticAssetURL(
                    "/images/gallery/technical-characteristics/model_side.jpg"
                  )}
                  alt={t("technicalDetails.side")}
                  title={t("technicalDetails.side")}
                />
                <UnderLine horizontal />
                <ParameterName>{t("technicalDetails.length")}</ParameterName>
                <Typography>
                  {activePreconfiguration.length}
                  {t("general.meterSymbol")}
                </Typography>
              </ItemContainer>
            </Container>
            <TextContainer data-id="technical-details-info">
              {activePreconfiguration.technicalCharacteristics.map(
                ({ label, description }, key) => (
                  <Row key={key}>
                    <ParameterName>{label}</ParameterName>
                    <Typography>{description}</Typography>
                  </Row>
                )
              )}
            </TextContainer>
            <TextContainer data-id="technical-details-info">
              <Row>
                <ParameterName margin="2rem 0 0">
                  {t("technicalDetails.descriptionTitle")}
                </ParameterName>
              </Row>
              <SpacedTypography margin="0 0 1rem" size="sm">
                {t("technicalDetails.description")}
              </SpacedTypography>
            </TextContainer>
            <Image
              src={getStaticAssetURL(
                "/images/gallery/technical-characteristics/additionnal.jpg"
              )}
              alt={t("general.technicalCharacteristicsLabel", {
                preconfigurationLabel: activePreconfiguration.label,
              })}
              title={t("general.technicalCharacteristicsLabel", {
                preconfigurationLabel: activePreconfiguration.label,
              })}
            />
            <StyledButton
              withBorder
              primaryColor={primaryColor}
              onClick={openTechnicalDetails}
            >
              <StyledDownloadIcon
                primaryColor={primaryColor}
                viewBox="0 0 32 32"
                data-id="technical-details-icon-download"
              />
              {t("technicalDetails.techList")}
            </StyledButton>
          </AnimationHelper>
        </Content>
      )}
    />
  );
};

export default TechnicalDetails;
